import {
  BASE_URL,
  apiDefaultErrorHandler,
  httpServiceGet,
  httpServiceUpdate,
} from "../utils/serviceUtils";

const getBrandGuide = async ({ ...props }) => {
  try {
    const url = new URL(`${BASE_URL}/brand_guide`);
    const data = await httpServiceGet({ url, ...props });
    return data;
  } catch (error) {
    apiDefaultErrorHandler(error);
    return null;
  }
};

const upsertBrandGuide = async (payload) => {
  try {
    const url = new URL(`${BASE_URL}/brand_guide`);
    const data = await httpServiceUpdate(url, payload);
    return data;
  } catch (error) {
    apiDefaultErrorHandler(error);
    return null;
  }
};

const brandGuideService = {
  getBrandGuide,
  upsertBrandGuide,
};

export default brandGuideService;

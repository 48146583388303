import React from "react";
import "./UserAvatar.scss";
import unknownUser from "../../assets/unknown-user.png";

const UserAvatarSizes = {
  small: "small",
  medium: "medium",
  large: "large",
};

function UserAvatar({ user, size = UserAvatarSizes.small, className }) {
  return (
    <div className={`user-avatar ${className}`}>
      <img className={size} src={user.photo || unknownUser} title={user.name} />
    </div>
  );
}

export { UserAvatar, UserAvatarSizes };

import { getSubdomain } from "./javascriptUtils";

function getSubdomainPrefix() {
  const currentSubdomain = getSubdomain(window.location.href);

  if (currentSubdomain) {
    return currentSubdomain;
  }

  return "localhost";
}

const userSession = {
  setSession(data) {
    if (!data?.accessToken || !data?.user || !data?.agency) {
      return;
    }

    localStorage.setItem(
      `${getSubdomainPrefix()}-currentUser`,
      JSON.stringify(data.user),
    );
    localStorage.setItem(
      `${getSubdomainPrefix()}-agency`,
      JSON.stringify(data.agency),
    );
    localStorage.setItem(`${getSubdomainPrefix()}-token`, data.accessToken);
  },

  setCurrentUser(user) {
    if (!user) {
      return;
    }

    localStorage.setItem(
      `${getSubdomainPrefix()}-currentUser`,
      JSON.stringify(user),
    );
  },

  setCurrentClient(client) {
    if (!client) {
      return;
    }

    localStorage.setItem(
      `${getSubdomainPrefix()}-currentClient`,
      JSON.stringify(client),
    );
  },

  getCurrentUser() {
    const user = localStorage.getItem(`${getSubdomainPrefix()}-currentUser`);
    return user ? JSON.parse(user) : null;
  },

  getToken() {
    return localStorage.getItem(`${getSubdomainPrefix()}-token`);
  },

  getCurrentClient() {
    const client = localStorage.getItem(
      `${getSubdomainPrefix()}-currentClient`,
    );
    return client ? JSON.parse(client) : null;
  },

  getCurrentAgency() {
    const agency = localStorage.getItem(`${getSubdomainPrefix()}-agency`);
    return agency ? JSON.parse(agency) : null;
  },

  isLoggedIn() {
    return this.getCurrentUser() && this.getCurrentAgency() && this.getToken();
  },

  removeSession() {
    localStorage.removeItem(`${getSubdomainPrefix()}-currentUser`);
    localStorage.removeItem(`${getSubdomainPrefix()}-agency`);
    localStorage.removeItem(`${getSubdomainPrefix()}-token`);
    localStorage.removeItem(`${getSubdomainPrefix()}-currentClient`);
  },

  removeCurrentClient() {
    localStorage.removeItem(`${getSubdomainPrefix()}-currentClient`);
  },
};

export default userSession;

import React, { useContext, useState, useEffect } from "react";
import "./SignupPage.scss";
import userService from "../../services/userService";
import userSession from "../../utils/userSession";
import { AppContext, navbarOptions } from "../../App";
import Button from "../../components/Button/Button";
import { validateEmail } from "../../utils/inputValidations";
import { validatePassword } from "../../utils/inputValidations";
import Card from "../../components/Card/Card";
import { Input, InputPhotoUploader } from "../../components/Input/Input";
import {
  containsLocalhost,
  getParamsFromUrl,
  getSubdomain,
} from "../../utils/javascriptUtils";
import Loader from "../../components/Loader/Loader";

function SignupPage() {
  const { changeNavbar } = useContext(AppContext);
  const [name, setName] = useState("");
  const [subdomain, setSubdomain] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [photo, setPhoto] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [invalidToken, setInvalidToken] = useState(false);
  const [loading, setLoading] = useState(false);
  const urlParams = getParamsFromUrl(window.location.search);
  const token = urlParams.token;
  const currentSubdomain = getSubdomain(window.location.href);
  let localhost = false;

  if (!currentSubdomain && containsLocalhost(window.location.href)) {
    localhost = true;
  }

  useEffect(() => {
    async function verifyToken() {
      if (!token) {
        setInvalidToken(true);
        window.location.href = "/";
        return;
      }

      setLoading(true);

      const response = await userService.verifyToken(token);

      setLoading(false);

      if (response.error) {
        setInvalidToken(true);
        return;
      }

      if (!response.data.isPending) {
        setInvalidToken(true);
        window.location.href = "/";
        return;
      }

      setEmail(response.data.email);
    }

    verifyToken();
  }, []);

  if ((!localhost && !currentSubdomain) || invalidToken) {
    return (
      <div className="page center-content">
        <Card title="Link inválido">
          <p>O link de verificação é inválido.</p>
        </Card>
      </div>
    );
  }

  const handleSignup = async (event) => {
    event.preventDefault();
    setFormSubmitted(true);

    if (!formIsValid()) {
      return;
    }

    const payload = {
      name: name,
      email: email,
      password: password,
      photo: photo,
      token: token,
      subdomain: localhost ? subdomain : currentSubdomain,
    };

    setLoading(true);

    const data = await userService.signup(payload);

    setLoading(false);

    if (!data?.accessToken || !data?.user) {
      return;
    }

    userSession.setSession(data);
    changeNavbar(navbarOptions.agency);
  };

  function formIsValid() {
    if (!photo) {
      return false;
    }

    if (!name) {
      return false;
    }
    if (!validateEmail(email).isValid) {
      return false;
    }
    if (!validatePassword(password).isValid) {
      return false;
    }
    if (password !== passwordConfirmation) {
      return false;
    }

    return true;
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="page center-content">
      <Card title="Bem vindo(a)!">
        <InputPhotoUploader
          value={photo}
          onChange={setPhoto}
          validationError={formSubmitted && !photo && "Foto é obrigatória"}
        />
        {localhost && (
          <Input
            label="Agência"
            value={subdomain}
            onChange={setSubdomain}
            disabled
            className="margin-top-small margin-bottom-small"
          />
        )}
        <Input
          label="Nome"
          type="text"
          value={name}
          onChange={setName}
          validationError={formSubmitted && !name && "Nome é obrigatório"}
        />
        <Input
          label="Email"
          type="email"
          value={email}
          onChange={setEmail}
          validationError={
            formSubmitted &&
            !validateEmail(email).isValid &&
            validateEmail(email).errorMessage
          }
          className="margin-top-medium"
          disabled
        />
        <Input
          label="Senha"
          type="password"
          value={password}
          onChange={setPassword}
          validationError={
            formSubmitted &&
            !validatePassword(password).isValid &&
            validatePassword(password).errorMessage
          }
          className="margin-top-medium"
        />
        <Input
          label="Confirme sua senha"
          type="password"
          value={passwordConfirmation}
          onChange={setPasswordConfirmation}
          validationError={
            formSubmitted &&
            password !== passwordConfirmation &&
            "As senhas não coincidem"
          }
          className="margin-top-medium"
        />

        <Button onClick={handleSignup} className="margin-top-medium">
          Entrar na plataforma
        </Button>
      </Card>
    </div>
  );
}

export default SignupPage;

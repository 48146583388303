import React, { useContext, useState } from "react";
import "./CrudArea.scss";
import { EllipsisVerticalIcon } from "@heroicons/react/24/solid";
import { UserAvatar } from "../UserAvatar/UserAvatar";
import SortComponent from "../SortComponent/SortComponent";
import Button from "../Button/Button";
import userSession from "../../utils/userSession";
import { AppContext, navbarOptions } from "../../App";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import { CrudContext } from "./CrudArea";
import clientService from "../../services/clientService";
import { ImageUploadTypes, Input, InputPhotoUploader } from "../Input/Input";
import ManageClientUsersModal from "../ManageClientUsersModal/ManageClientUsersModal";
import ActionsDropdown from "../ActionsDropdown/ActionsDropdown";
import { useNavigate } from "react-router-dom";
import noLogo from "../../assets/no-logo.jpg";
import Loader from "../Loader/Loader";
import UserRestrictedArea from "../UserRestrictedArea/UserRestrictedArea";

export function ClientsTable() {
  const {
    data,
    openActionsDropdown,
    closeActionsDropdown,
    openDeleteConfirmationModal,
    closeDeleteConfirmationModal,
    openConfirmDeleteModal,
    actionsDropdownId,
    openCreateUpdateModal,
    reloadData,
  } = useContext(CrudContext);

  const [idToDelete, setIdToDelete] = useState(null);
  const { changeNavbar } = useContext(AppContext);
  const [clientUsersModalOpenId, setOpenClientUsersModal] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  function openClientUsersModal(clientId) {
    setOpenClientUsersModal(clientId);
  }

  function closeClientUsersModal() {
    setOpenClientUsersModal(false);
    reloadData();
  }

  function openClientPage(client) {
    userSession.setCurrentClient(client);
    changeNavbar(navbarOptions.client);
    navigate(`/cliente/${client?.id}/tarefas`);
  }

  function openEditModal(client) {
    openCreateUpdateModal(client);
    closeActionsDropdown();
  }

  async function handleDelete() {
    setLoading(true);
    await clientService.deleteClient(idToDelete);
    setLoading(false);
    reloadData();
    closeDeleteConfirmationModal();
  }

  function getOptionsForClient(client) {
    const options = [
      {
        name: "Gerenciar Usuários",
        onClick: (e) => {
          e.stopPropagation();
          openClientUsersModal(client.id);
          closeActionsDropdown();
        },
      },
      {
        name: "Editar",
        onClick: (e) => {
          e.stopPropagation();
          openEditModal(client);
          closeActionsDropdown();
        },
      },
      {
        name: "Excluir",
        onClick: (e) => {
          e.stopPropagation();
          setIdToDelete(client.id);
          openDeleteConfirmationModal();
          closeActionsDropdown();
        },
      },
    ];

    return options;
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <ManageClientUsersModal
        isOpen={clientUsersModalOpenId}
        onClose={closeClientUsersModal}
        clientId={clientUsersModalOpenId}
      />
      <ConfirmationModal
        isOpen={openConfirmDeleteModal}
        onClose={closeDeleteConfirmationModal}
        title={"Excluir Cliente"}
        message={"Tem certeza que deseja excluir este cliente?"}
        onConfirm={handleDelete}
      />

      <table>
        <thead>
          <tr>
            <th className="column-2"></th>
            <th className="column-4">
              Nome <SortComponent sort_by="name" />
            </th>
            <th className="column-4">Usuários</th>
            <th className="column-2"></th>
          </tr>
        </thead>
        <tbody>
          {data.clients?.map((client, clientIdx) => {
            return (
              <tr key={client.id} onClick={() => openClientPage(client)}>
                <td className="column-2 align-center">
                  <img src={client.logo || noLogo} className="logo" />
                </td>
                <td className="column-4">{client.name}</td>
                <td className="column-4">
                  <div className="user-avatar-container">
                    {client.users?.map((user, idx) => {
                      return <UserAvatar user={user} key={idx} />;
                    })}
                  </div>
                </td>
                <td className="column-2 align-right">
                  <UserRestrictedArea>
                    <EllipsisVerticalIcon
                      className="crud-item-action"
                      onClick={(e) => {
                        e.stopPropagation();
                        openActionsDropdown(clientIdx);
                      }}
                    />
                    {actionsDropdownId === clientIdx && (
                      <ActionsDropdown
                        closeActionsDropdown={closeActionsDropdown}
                        options={getOptionsForClient(client)}
                      />
                    )}
                  </UserRestrictedArea>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

export function ClientModalContent() {
  const { closeCrudModal, reloadData, createUpdateModel } =
    useContext(CrudContext);
  const client = createUpdateModel.id ? createUpdateModel : null;
  const editMode = !!client;
  const [logo, setLogo] = useState(editMode ? client?.logo : null);
  const [name, setName] = useState(editMode ? client?.name : "");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  function validateInputs() {
    if (!name) {
      return false;
    }

    return true;
  }

  async function handleSave() {
    setFormSubmitted(true);

    if (!validateInputs()) {
      return;
    }

    const payload = {
      name: name,
      ...(logo instanceof Blob ? { logo } : {}),
    };

    setLoading(true);

    const response = editMode
      ? await clientService.updateClient(client.id, payload)
      : await clientService.createClient(payload);

    setLoading(false);

    if (!response) {
      return;
    }

    reloadData();

    closeCrudModal();
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="crud-form">
      <div className="form-content">
        <InputPhotoUploader
          onChange={setLogo}
          value={logo}
          type={ImageUploadTypes.logo}
        />
        <Input
          label="Nome"
          value={name}
          onChange={setName}
          placeholder="Nome"
          validationError={formSubmitted && !name && "Nome é obrigatório"}
        />
      </div>

      <div className="form-footer">
        <Button type="secondary" onClick={closeCrudModal}>
          Cancelar
        </Button>
        <Button type="primary" onClick={handleSave}>
          Salvar
        </Button>
      </div>
    </div>
  );
}

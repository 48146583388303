import React from "react";
import { Navigate } from "react-router-dom";
import userSession from "../../utils/userSession";

const PrivateRoute = ({ Component, ...props }) => {
  const isLoggedIn = userSession.isLoggedIn();
  const currentClient = userSession.getCurrentClient();

  if (!isLoggedIn) return <Navigate to="/login" />;

  return currentClient ? <Navigate to="/" /> : <Component {...props} />;
};

export default PrivateRoute;

import React, { useEffect, useState, createContext, useContext } from "react";
import "./CrudArea.scss";
import clientService from "../../services/clientService";
import { pluralize } from "../../utils/javascriptUtils";
import PaginationComponent from "../PaginationComponent/PaginationComponent";
import Badge from "../Badge/Badge";
import Button, { ButtonTypes } from "../Button/Button";
import emptyState from "../../assets/empty-state.svg";
import loadErrorState from "../../assets/load-error-state.svg";
import Loader from "../Loader/Loader";
import Modal, { ModalIcons } from "../Modal/Modal";
import { ClientModalContent, ClientsTable } from "./ClientCrudElements";
import userService from "../../services/userService";
import { UserModalContent, UsersTable } from "./UserCrudElements";
import { Input } from "../Input/Input";
import UserRestrictedArea from "../UserRestrictedArea/UserRestrictedArea";

export const CrudContext = createContext();

export function CrudArea({ model }) {
  const modelProps = getModelProps(model);
  if (!modelProps) return null;

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadError, setLoadError] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("");
  const [order, setOrder] = useState("");
  const [filters, setFilters] = useState([]);
  const [selected, setSelected] = useState([]);
  const [createUpdateModel, setCreateUpdateModal] = useState(false);
  const [actionsDropdownId, setActionsDropdownId] = useState(null);
  const [openConfirmDeleteModal, setOpenConfirmDelete] = useState(false);
  const [reload, setReload] = useState(false);

  function openActionsDropdown(id) {
    setActionsDropdownId(id);
  }

  function closeActionsDropdown() {
    setActionsDropdownId(null);
  }

  function openDeleteConfirmationModal() {
    setOpenConfirmDelete(true);
    closeActionsDropdown();
  }

  function closeDeleteConfirmationModal() {
    setOpenConfirmDelete(false);
  }

  function openCreateUpdateModal(model = false) {
    setCreateUpdateModal(model || true);
  }

  function closeCrudModal() {
    setCreateUpdateModal(false);
  }

  function reloadData() {
    setReload(!reload);
  }

  const contextValue = {
    data,
    setData,
    loading,
    modelProps,
    page,
    setPage,
    perPage,
    setPerPage,
    search,
    setSearch,
    sort,
    setSort,
    order,
    setOrder,
    filters,
    setFilters,
    selected,
    setSelected,
    openCreateUpdateModal,
    closeCrudModal,
    openActionsDropdown,
    closeActionsDropdown,
    createUpdateModel,
    openDeleteConfirmationModal,
    closeDeleteConfirmationModal,
    actionsDropdownId,
    openConfirmDeleteModal,
    reloadData,
    loadError,
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setLoadError(false);

      const result = await modelProps.getModel({
        page,
        perPage,
        search,
        sortBy: sort,
        sortOrder: order,
        filters,
      });

      if (!result) {
        setLoadError(true);
      }

      setLoading(false);
      setData(result);
    };

    fetchData();
  }, [page, perPage, search, sort, order, filters, reload]);

  let modalTitle = "";
  let modalSubtitle = "";
  let modalIcon = "";

  if (modelProps.model === crudModels.client) {
    modalTitle = createUpdateModel.id ? "Editar Cliente" : "Adicionar cliente";
  } else if (modelProps.model === crudModels.user) {
    modalIcon = ModalIcons.user;
    modalTitle = "Convide seus colaboradores";
    modalSubtitle = "Adicione colaboradores para a sua empresa";
  }

  return (
    <div className="crud-area margin-top-extra-large">
      <CrudContext.Provider value={contextValue}>
        <Modal
          isOpen={createUpdateModel}
          onClose={closeCrudModal}
          Icon={modalIcon}
          title={modalTitle}
          subtitle={modalSubtitle}
        >
          {modelProps.model === crudModels.client && <ClientModalContent />}
          {modelProps.model === crudModels.user && <UserModalContent />}
        </Modal>

        <div className="crud-area-header">
          <Input
            type="text"
            placeholder="Buscar"
            value={search}
            onChange={(value) => {
              setSearch(value);
              if (page > 1) {
                setPage(1);
              }
            }}
            className="width-40"
          />
        </div>
        <div className="crud-card">
          <div className="crud-card-header">
            <div className="left-container">
              <h2>{pluralize(modelProps.name)}</h2>
              {!loading && !loadError && (
                <Badge type="primary" className="margin-left-small">
                  {data?.total || 0}{" "}
                  {data?.total != 1
                    ? pluralize(modelProps.name, data?.total)
                    : modelProps.name}
                </Badge>
              )}
            </div>
            <UserRestrictedArea>
              <Button
                type={ButtonTypes.primary}
                onClick={openCreateUpdateModal}
              >
                Adicionar {modelProps.name}
              </Button>
            </UserRestrictedArea>
          </div>
          <CrudTable />
        </div>
      </CrudContext.Provider>
    </div>
  );
}

function CrudTable() {
  const { data, loading, modelProps, loadError } = useContext(CrudContext);

  if (loading) {
    return (
      <div className="empty-table">
        <Loader />
      </div>
    );
  }

  if (loadError) {
    return (
      <div className="empty-table">
        <img src={loadErrorState} alt="Logo" />
      </div>
    );
  }

  if (!data || !data[modelProps.dataKey]?.length) {
    return (
      <div className="empty-table">
        <img src={emptyState} alt="Logo" />
      </div>
    );
  }

  return (
    <div>
      {modelProps.model === crudModels.client && <ClientsTable />}
      {modelProps.model === crudModels.user && <UsersTable />}
      <PaginationComponent total={data.total} />
    </div>
  );
}

export const crudModels = {
  client: "client",
  user: "user",
};

function getModelProps(model) {
  const modelProps = {
    client: {
      model: model,
      name: "Cliente",
      getModel: clientService.getClients,
      dataKey: "clients",
    },
    user: {
      model: model,
      name: "Colaborador",
      getModel: userService.getUsers,
      dataKey: "users",
    },
  };

  return modelProps[model];
}

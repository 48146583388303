import React from "react";
import "./ConfirmationModal.scss";
import Modal, { ModalIcons } from "../Modal/Modal";
import Button, { ButtonTypes } from "../Button/Button";

function ConfirmationModal({
  isOpen,
  onClose,
  title = "Confirme esta ação",
  message,
  onConfirm,
}) {
  if (!isOpen) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      Icon={ModalIcons.warning}
    >
      <div className="confirmation-modal">
        <div className="content">
          {message && <p className="confirmation-message">{message}</p>}
        </div>

        <div className="footer">
          <Button type="secondary" onClick={onClose}>
            Cancelar
          </Button>
          <Button type={ButtonTypes.primary} onClick={onConfirm}>
            Confirmar
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default ConfirmationModal;

import React from "react";
import { Navigate, useParams } from "react-router-dom";
import userSession from "../../utils/userSession";

const PrivateRouteFromClient = ({ Component, ...props }) => {
  const isLoggedIn = userSession.isLoggedIn();
  const currentClient = userSession.getCurrentClient();
  const { client_id } = useParams();

  if (client_id !== currentClient?.id) {
    // TODO
    // faz um get que retorna o cliente somente se o current user tiver acesso
    // se não tiver acesso, notifica que não tem acesso a esse cliente
  }

  if (!isLoggedIn) return <Navigate to="/login" />;

  return currentClient ? <Component {...props} /> : <Navigate to="/" />;
};

export default PrivateRouteFromClient;

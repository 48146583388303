import React, { useContext, useEffect, useRef, useState } from "react";
import "./AgentChatWindow.scss";
import Button from "../Button/Button";
import InfoCard from "../InfoCard/InfoCard";
import { Input } from "../Input/Input";
import userSession from "../../utils/userSession";
import { UserAvatar, UserAvatarSizes } from "../UserAvatar/UserAvatar";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import ReactMarkdown from "react-markdown";

export const authors = {
  copyAgent: "assistant",
  currentUser: "user",
};

function AgentChatWindow({
  className = "",
  initialMessage = "Olá. Posso te ajudar?",
  agentService,
  taskId,
  previousConversation,
  updatePreviousConversation,
  startedConversation = true,
  onItemClick,
  extraPaddingTop = false,
}) {
  const emptyConversation = [
    {
      role: authors.copyAgent,
      content: {
        answer: initialMessage,
        contentItems: [],
      },
    },
  ];
  const [conversation, setConversation] = useState(
    previousConversation || emptyConversation,
  );
  const [startConversation, setStartConversation] =
    useState(startedConversation);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  async function askAgent(input) {
    if (!startConversation) {
      setStartConversation(true);
    }

    let currentConversation = [...conversation];

    if (input) {
      currentConversation = [
        ...currentConversation,
        {
          role: authors.currentUser,
          content: {
            answer: input,
          },
        },
      ];

      setConversation(currentConversation);
    }

    const payload = {
      context: currentConversation,
      clientId: userSession.getCurrentClient().id,
    };

    if (taskId) {
      payload.taskId = taskId;
    }

    setError(false);
    setLoading(true);

    const response = await agentService(payload);

    setLoading(false);

    if (!response?.answer) {
      setError(true);
      return;
    }

    const updatedConversation = [
      ...currentConversation,
      {
        role: authors.copyAgent,
        content: response,
      },
    ];

    setConversation(updatedConversation);

    if (updatePreviousConversation) {
      updatePreviousConversation(updatedConversation);
    }
  }

  const currentUser = userSession.getCurrentUser();
  const endOfConversationRef = useRef(null);
  const [userInput, setUserInput] = useState("");

  useEffect(() => {
    endOfConversationRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [conversation]);

  return (
    <>
      <div className={`agent-conversation-component ${className}`}>
        <div
          className={`conversation ${startConversation && "conversation-started"} ${extraPaddingTop && "extra-padding-top"}`}
        >
          <div className="messages-container">
            {conversation.map((message, index) => (
              <>
                {message.role === authors.currentUser ? (
                  <div
                    key={index}
                    className="message current-user margin-top-small"
                  >
                    <div className="current-user-msg">
                      <div className="text margin-left-small">
                        {message?.content?.answer}
                      </div>
                      <UserAvatar
                        user={currentUser}
                        className="margin-left-small"
                        size={UserAvatarSizes.medium}
                      />
                    </div>
                  </div>
                ) : (
                  <div key={index} className="message margin-top-medium">
                    <div className="message-container">
                      {message.role === authors.copyAgent && (
                        <img
                          src="https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/9.png"
                          className="agent-image"
                        />
                      )}
                      <div className="">
                        <ReactMarkdown>
                          {message?.content?.answer}
                        </ReactMarkdown>
                      </div>
                    </div>
                    {message?.content?.contentItems?.length > 0 && (
                      <div className="briefing-cards">
                        {message.content.contentItems.map((item, index) => (
                          <InfoCard
                            key={index}
                            onClick={() => {
                              if (onItemClick && item?.text) {
                                onItemClick(item);
                              }
                            }}
                          >
                            <ReactMarkdown>{item?.text}</ReactMarkdown>
                          </InfoCard>
                        ))}
                      </div>
                    )}
                  </div>
                )}
              </>
            ))}

            {loading && (
              <>
                <div className="message">
                  <div className="message-container">
                    <img
                      src="https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/9.png"
                      className="agent-image"
                    />
                    <LoadingDots />
                  </div>
                </div>
              </>
            )}

            {error && (
              <div className="error-message margin-top-large">
                <ExclamationCircleIcon className="icon" />
                Ops, ocorreu um erro! Se persistir, por favor contate um
                administrador.{" "}
                <a onClick={() => askAgent("")}>Tentar novamente</a>
              </div>
            )}

            {!startConversation && (
              <div className="start-input margin-top-large">
                <Button
                  onClick={() => {
                    askAgent("Iniciar Conversa");
                    setStartConversation(true);
                  }}
                  type="submit"
                >
                  Iniciar Conversa
                </Button>
              </div>
            )}
            <div ref={endOfConversationRef} />
          </div>
          <div className="conversation-user-input margin-top-medium">
            {startConversation && (
              <>
                <form
                  onSubmit={(event) => {
                    event.preventDefault();
                    askAgent(userInput);
                    setUserInput("");
                  }}
                  className="conversation-user-input"
                >
                  <div className="input-area">
                    <Input
                      placeholder="Digite sua ideia..."
                      value={userInput}
                      onChange={setUserInput}
                    />
                  </div>
                  <Button type="submit">Enviar</Button>
                </form>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

function LoadingDots() {
  const [dots, setDots] = useState("");

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDots((dots) => (dots.length < 3 ? dots + "." : ""));
    }, 500);

    return () => clearInterval(intervalId);
  }, []);

  return <div>Pensando{dots}</div>;
}

export default AgentChatWindow;

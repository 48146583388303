import React from "react";
import "./NotFoundPage.scss";

function NotFoundPage() {
  return (
    <div className="not-found-page">
      <h1>Página não encontrada</h1>
    </div>
  );
}

export default NotFoundPage;

import {
  BASE_URL,
  apiDefaultErrorHandler,
  httpServiceCreate,
  httpServiceDelete,
  httpServiceGet,
  httpServiceUpdate,
  payloadTypes,
} from "../utils/serviceUtils";

const getClients = async ({ ...props }) => {
  try {
    const url = new URL(`${BASE_URL}/client`);
    const data = await httpServiceGet({ url, ...props });
    return data;
  } catch (error) {
    apiDefaultErrorHandler(error);
    return null;
  }
};

const createClient = async (payload) => {
  try {
    const url = new URL(`${BASE_URL}/client`);
    const formData = new FormData();

    for (let key in payload) {
      formData.append(key, payload[key]);
    }

    const data = await httpServiceCreate(url, formData, payloadTypes.FORM_DATA);
    return data;
  } catch (error) {
    apiDefaultErrorHandler(error);
    return null;
  }
};

const updateClient = async (clientId, payload) => {
  try {
    const url = new URL(`${BASE_URL}/client/${clientId}`);
    const formData = new FormData();
    for (let key in payload) {
      formData.append(key, payload[key]);
    }

    const data = await httpServiceUpdate(url, formData, payloadTypes.FORM_DATA);
    return data;
  } catch (error) {
    apiDefaultErrorHandler(error);
    return null;
  }
};

const deleteClient = async (clientId) => {
  try {
    const url = new URL(`${BASE_URL}/client/${clientId}`);
    await httpServiceDelete(url);
    return true;
  } catch (error) {
    apiDefaultErrorHandler(error);
    return false;
  }
};

const clientService = {
  getClients,
  createClient,
  updateClient,
  deleteClient,
};

export default clientService;

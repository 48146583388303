import React from "react";
import { Navigate } from "react-router-dom";
import userSession from "../../utils/userSession";

const PublicRoute = ({ Component, ...props }) => {
  const isLoggedIn = userSession.isLoggedIn();
  return isLoggedIn ? <Navigate to="/" /> : <Component {...props} />;
};

export default PublicRoute;

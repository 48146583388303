/* ******************** SPECIFIC TEXT INSTRUCTIONS ******************** */

export const textInstructions = {
  hyperlinks: {
    text: "Hyperlinks (opcional)",
    placeholder: "Quero que o texto mencione os seguintes hyperlinks...",
    value: "hyperlinks",
  },
  hashtags: {
    text: "Hashtags (opcional)",
    placeholder: "Quero que tenha as seguintes hashtags...",
    value: "hashtags",
  },
  mentions: {
    text: "Menções (opcional)",
    placeholder: "Quero que mencione esses perfis, por tais razões...",
    value: "mentions",
  },
  text: {
    text: "Texto ou expressão obrigatória (opcional)",
    placeholder:
      "Quero que mencione algumas expressões ou textos específicos...",
    value: "text",
  },
};

const alltextInstructions = [
  textInstructions.hyperlinks,
  textInstructions.hashtags,
  textInstructions.mentions,
  textInstructions.text,
];

/* ******************** GOALS PER AREA ******************** */

const socialMediaGoals = {
  increaseBrandRecognition: {
    text: "Aumentar o Reconhecimento da Marca",
    value: "increaseBrandRecognition",
  },
  engageAudience: {
    text: "Engajar o Público",
    value: "engageAudience",
  },
  driveTrafficToWebsite: {
    text: "Dirigir Tráfego para o Site",
    value: "driveTrafficToWebsite",
  },
  generateLeads: {
    text: "Gerar Leads",
    value: "generateLeads",
  },
  promoteProductsOrServices: {
    text: "Promover Produtos ou Serviços",
    value: "promoteProductsOrServices",
  },
  educateAudience: {
    text: "Educar o Público",
    value: "educateAudience",
  },
  humanizeBrand: {
    text: "Humanizar a Marca",
    value: "humanizeBrand",
  },
  fosterBrandLoyalty: {
    text: "Fomentar a Lealdade à Marca",
    value: "fosterBrandLoyalty",
  },
  obtainFeedback: {
    text: "Obter Feedback",
    value: "obtainFeedback",
  },
  supportCustomerService: {
    text: "Apoiar o Atendimento ao Cliente",
    value: "supportCustomerService",
  },
  buildAuthority: {
    text: "Construir Autoridade",
    value: "buildAuthority",
  },
  promoteEvents: {
    text: "Promover Eventos",
    value: "promoteEvents",
  },
};

const pressGoals = {
  increaseVisibility: {
    text: "Aumentar a Visibilidade",
    value: "increaseVisibility",
  },
  announceNews: {
    text: "Anunciar Novidades",
    value: "announceNews",
  },
  generateCredibility: {
    text: "Gerar Credibilidade",
    value: "generateCredibility",
  },
  increaseEngagement: {
    text: "Aumentar o Engajamento",
    value: "increaseEngagement",
  },
  informThePublic: {
    text: "Informar o Público",
    value: "informThePublic",
  },
  attractInvestors: {
    text: "Atrair Investidores",
    value: "attractInvestors",
  },
  manageCrises: {
    text: "Gerenciar Crises",
    value: "manageCrises",
  },
  strengthenMediaRelationships: {
    text: "Fortalecer Relacionamentos com a Mídia",
    value: "strengthenMediaRelationships",
  },
  influencePublicOpinion: {
    text: "Influenciar a Opinião Pública",
    value: "influencePublicOpinion",
  },
  differentiateFromCompetitors: {
    text: "Diferenciação da Concorrência",
    value: "differentiateFromCompetitors",
  },
};

const blogGoals = {
  increaseWebsiteTraffic: {
    text: "Aumentar o Tráfego do Site",
    value: "increaseWebsiteTraffic",
  },
  establishAuthority: {
    text: "Estabelecer Autoridade",
    value: "establishAuthority",
  },
  engageAudience: {
    text: "Engajar o Público",
    value: "engageAudience",
  },
  generateLeads: {
    text: "Gerar Leads",
    value: "generateLeads",
  },
  educateAudience: {
    text: "Educar o Público",
    value: "educateAudience",
  },
  retainCustomers: {
    text: "Fidelizar Clientes",
    value: "retainCustomers",
  },
  improveSEO: {
    text: "Melhorar o SEO",
    value: "improveSEO",
  },
  supportSalesCycle: {
    text: "Suportar o Ciclo de Vendas",
    value: "supportSalesCycle",
  },
  promoteProductsOrServices: {
    text: "Promover Produtos ou Serviços",
    value: "promoteProductsOrServices",
  },
  shareCompanyUpdates: {
    text: "Compartilhar Atualizações da Empresa",
    value: "shareCompanyUpdates",
  },
  humanizeBrand: {
    text: "Humanizar a Marca",
    value: "humanizeBrand",
  },
  supportMarketingCampaigns: {
    text: "Apoiar Campanhas de Marketing",
    value: "supportMarketingCampaigns",
  },
  gatherFeedback: {
    text: "Obter Feedback",
    value: "gatherFeedback",
  },
};

const internalCommunicationGoals = {
  improveTransparency: {
    text: "Melhorar a Transparência",
    value: "improveTransparency",
  },
  alignEmployees: {
    text: "Alinhar os Colaboradores",
    value: "alignEmployees",
  },
  increaseEngagement: {
    text: "Aumentar o Engajamento",
    value: "increaseEngagement",
  },
  promoteOrganizationalCulture: {
    text: "Promover a Cultura Organizacional",
    value: "promoteOrganizationalCulture",
  },
  facilitateCollaboration: {
    text: "Facilitar a Colaboração",
    value: "facilitateCollaboration",
  },
  improveProductivity: {
    text: "Melhorar a Produtividade",
    value: "improveProductivity",
  },
  supportProfessionalDevelopment: {
    text: "Apoiar o Desenvolvimento Profissional",
    value: "supportProfessionalDevelopment",
  },
  recognizeAndReward: {
    text: "Reconhecer e Recompensar",
    value: "recognizeAndReward",
  },
  manageChanges: {
    text: "Gerenciar Mudanças",
    value: "manageChanges",
  },
  collectFeedback: {
    text: "Recolher Feedback",
    value: "collectFeedback",
  },
  strengthenCohesion: {
    text: "Fortalecer a Coesão",
    value: "strengthenCohesion",
  },
  ensureComplianceAndConsistency: {
    text: "Garantir Conformidade e Consistência",
    value: "ensureComplianceAndConsistency",
  },
  supportHealthAndWellBeing: {
    text: "Apoiar a Saúde e o Bem-Estar",
    value: "supportHealthAndWellBeing",
  },
  facilitateOnboarding: {
    text: "Facilitar a Adaptação de Novos Colaboradores",
    value: "facilitateOnboarding",
  },
};

const generalCommunicationGoals = {
  increaseBrandVisibility: {
    text: "Aumentar a Visibilidade da Marca",
    value: "increaseBrandVisibility",
  },
  engageTargetAudience: {
    text: "Engajar o Público-Alvo",
    value: "engageTargetAudience",
  },
  promoteProductsAndServices: {
    text: "Promover Produtos ou Serviços",
    value: "promoteProductsAndServices",
  },
  educateAndInform: {
    text: "Educar e Informar",
    value: "educateAndInform",
  },
  generateLeadsAndSales: {
    text: "Gerar Leads e Vendas",
    value: "generateLeadsAndSales",
  },
  buildAndStrengthenReputation: {
    text: "Construir e Fortalecer a Reputação",
    value: "buildAndStrengthenReputation",
  },
  fosterBrandLoyalty: {
    text: "Fomentar a Lealdade à Marca",
    value: "fosterBrandLoyalty",
  },
  supportStakeholderRelationships: {
    text: "Apoiar o Relacionamento com Stakeholders",
    value: "supportStakeholderRelationships",
  },
  stimulateInnovation: {
    text: "Estimular a Inovação",
    value: "stimulateInnovation",
  },
  managePublicPerception: {
    text: "Gerenciar a Percepção Pública",
    value: "managePublicPerception",
  },
  increaseTrafficAndVisibilityOnline: {
    text: "Aumentar o Tráfego e a Visibilidade Online",
    value: "increaseTrafficAndVisibilityOnline",
  },
  differentiateFromCompetition: {
    text: "Diferenciar-se da Concorrência",
    value: "differentiateFromCompetition",
  },
  supportIntegratedCampaigns: {
    text: "Apoiar Campanhas Integradas",
    value: "supportIntegratedCampaigns",
  },
  measureAndAnalyzeResults: {
    text: "Medir e Analisar Resultados",
    value: "measureAndAnalyzeResults",
  },
};

/* ******************** CHANNELS AND DELIVERIES PER AREA ******************** */

const socialMediaChannels = {
  facebook: {
    text: "Facebook",
    value: "facebook",
    deliverables: [
      {
        text: "Post",
        value: "post",
        textInstructions: alltextInstructions,
      },
      {
        text: "Stories",
        value: "stories",
        textInstructions: alltextInstructions,
      },
    ],
  },
  instagram: {
    text: "Instagram",
    value: "instagram",
    deliverables: [
      {
        text: "Post",
        value: "post",
        textInstructions: [
          textInstructions.hashtags,
          textInstructions.mentions,
          textInstructions.text,
        ],
      },
      {
        text: "Stories",
        value: "stories",
        textInstructions: [
          textInstructions.hashtags,
          textInstructions.mentions,
          textInstructions.text,
        ],
      },
      {
        text: "Reels",
        value: "reels",
        textInstructions: [
          textInstructions.hashtags,
          textInstructions.mentions,
          textInstructions.text,
        ],
      },
    ],
  },
  linkedin: {
    text: "LinkedIn",
    value: "linkedin",
    deliverables: [
      {
        text: "Post",
        value: "post",
        textInstructions: [
          textInstructions.hyperlinks,
          textInstructions.mentions,
          textInstructions.text,
        ],
      },
      {
        text: "Artigo",
        value: "article",
        textInstructions: [
          textInstructions.hyperlinks,
          textInstructions.mentions,
          textInstructions.text,
        ],
      },
    ],
  },
  whatsapp: {
    text: "Whatsapp",
    value: "whatsapp",
    deliverables: [
      {
        text: "Texto",
        value: "text",
        textInstructions: [textInstructions.hyperlinks, textInstructions.text],
      },
      { text: "Imagem", value: "image" },
      {
        text: "Texto e Imagem",
        value: "textAndImage",
        textInstructions: [textInstructions.hyperlinks, textInstructions.text],
      },
    ],
  },
  other: {
    text: "Outra",
    value: "other",
    deliverables: [
      {
        text: "Texto",
        value: "text",
        textInstructions: [textInstructions.hyperlinks, textInstructions.text],
      },
      { text: "Imagem", value: "image" },
      {
        text: "Texto e Imagem",
        value: "textAndImage",
        textInstructions: [textInstructions.hyperlinks, textInstructions.text],
      },
    ],
  },
};

const pressDeliverables = [
  {
    text: "Nota de Imprensa",
    value: "pressRelease",
    textInstructions: [textInstructions.hyperlinks, textInstructions.text],
  },
  {
    text: "Artigo",
    value: "article",
    textInstructions: [textInstructions.hyperlinks, textInstructions.text],
  },
  {
    text: "Entrevista",
    value: "interview",
    textInstructions: [textInstructions.mentions, textInstructions.text],
  },
  {
    text: "Comunicado de Imprensa",
    value: "pressStatement",
    textInstructions: [textInstructions.hyperlinks, textInstructions.text],
  },
  {
    text: "Release",
    value: "release",
    textInstructions: [textInstructions.hyperlinks, textInstructions.text],
  },
  {
    text: "Briefing",
    value: "briefing",
    textInstructions: [textInstructions.hyperlinks, textInstructions.text],
  },
  {
    text: "Redação",
    value: "redacao",
    textInstructions: [textInstructions.hyperlinks, textInstructions.text],
  },
];
const pressChannels = {
  onlineMedia: {
    text: "Portais de Notícias Online",
    value: "onlineMedia",
    deliverables: pressDeliverables,
  },
  newspapers: {
    text: "Jornais Impressos e Digitais",
    value: "newspapers",
    deliverables: pressDeliverables,
  },
  magazines: {
    text: "Revistas",
    value: "magazines",
    deliverables: pressDeliverables,
  },
};

const blogDeliverables = [
  {
    text: "Blog Post",
    value: "post",
    textInstructions: [textInstructions.hyperlinks, textInstructions.text],
  },
];

const internalCommunicationDeliverables = [
  {
    text: "E-mail",
    value: "email",
    textInstructions: [textInstructions.hyperlinks, textInstructions.text],
  },
  {
    text: "Comunicado",
    value: "statement",
    textInstructions: [textInstructions.hyperlinks, textInstructions.text],
  },
  {
    text: "Informativo",
    value: "newsletter",
    textInstructions: [textInstructions.hyperlinks, textInstructions.text],
  },
  {
    text: "Outro",
    value: "other",
    textInstructions: [textInstructions.hyperlinks, textInstructions.text],
  },
];

const otherDeliverables = [
  {
    text: "Texto",
    value: "text",
    textInstructions: alltextInstructions,
  },
];

/* ******************** OPTIONS HIERARCHY ******************** */

export const taskOptions = {
  socialMedia: {
    text: "Redes Sociais",
    label: "Redes Sociais",
    value: "socialMedia",
    goals: socialMediaGoals,
    channels: socialMediaChannels,
  },
  press: {
    text: "Imprensa",
    label: "Imprensa",
    value: "press",
    goals: pressGoals,
    channels: pressChannels,
    deliverables: pressDeliverables,
  },
  blog: {
    text: "Blog",
    label: "Blog",
    value: "blog",
    goals: blogGoals,
    channels: [],
    deliverables: blogDeliverables,
  },
  internalCommunication: {
    text: "Comunicação Interna",
    label: "Comunicação Interna",
    value: "internalCommunication",
    goals: internalCommunicationGoals,
    channels: [],
    deliverables: internalCommunicationDeliverables,
  },
  other: {
    text: "Outra",
    label: "Outra Área",
    value: "other",
    goals: generalCommunicationGoals,
    channels: [],
    deliverables: otherDeliverables,
  },
};

/* ******************** EMPTY OPTIONS ******************** */

export const noSection = {
  id: "noSection",
  name: {
    value: "Não aborda nenhuma editoria específica",
  },
};

export const noSpokesperson = {
  id: "noSpokesperson",
  name: {
    value: "O texto não é escrito em nome de um porta voz específico",
  },
};

export const noTargetAudience = {
  id: "noTargetAudience",
  name: {
    value: "Não há um público alvo específico",
  },
};

export const noProduct = {
  id: "noProduct",
  name: {
    value: "O texto não menciona um produto ou serviço específico",
  },
};

/* ******************** TASK AREAS ******************** */

export const taskAreasOptions = {
  socialMedia: {
    label: "Redes Sociais",
    value: "socialMedia",
    goals: Object.keys(socialMediaGoals).map((goal) => ({
      label: socialMediaGoals[goal].text,
      value: goal,
    })),
  },
  press: {
    label: "Imprensa",
    value: "press",
    goals: Object.keys(pressGoals).map((goal) => ({
      label: pressGoals[goal].text,
      value: goal,
    })),
  },
  blog: {
    label: "Blog",
    value: "blog",
    goals: Object.keys(blogGoals).map((goal) => ({
      label: blogGoals[goal].text,
      value: goal,
    })),
  },
  internalCommunication: {
    label: "Comunicação Interna",
    value: "internalCommunication",
    goals: Object.keys(internalCommunicationGoals).map((goal) => ({
      label: internalCommunicationGoals[goal].text,
      value: goal,
    })),
  },
  other: {
    label: "Outra",
    value: "other",
    goals: Object.keys(generalCommunicationGoals).map((goal) => ({
      label: generalCommunicationGoals[goal].text,
      value: goal,
    })),
  },
};

/* ******************** TASK STATUSES ******************** */

export const TaskStatus = {
  backlog: "backlog",
  inProgress: "inProgress",
  review: "review",
  done: "done",
};

export const taskSubmissionStatuses = {
  start: "Iniciada",
  finish: "Finalizada",
  pause: "Pausada",
};

export const taskReviewStatuses = {
  approved: "Aprovada",
  disapproved: "Reprovada",
};

/* ******************** TASK FIELDS ******************** */

export const taskFields = {
  topic: "topic",
  text: "text",
  briefDesign: "briefDesign",
};

export const taskFieldsText = {
  [taskFields.topic]: "Assunto",
  [taskFields.text]: "Texto da Peça",
  [taskFields.briefDesign]: "Brief de Arte",
};

/* ******************** UTILS ******************** */

export function getDeliverableOptions(task) {
  const area = task.details?.area;

  if (!area) {
    return [];
  }

  if (area === taskOptions.socialMedia.value) {
    return taskOptions.socialMedia.channels[task.details?.channel]
      ?.deliverables;
  }

  return taskOptions[area]?.deliverables || [];
}

export function getTaskDeliverableText(task) {
  const area = task.details?.area;
  const channel = task.details?.channel;
  const deliverable = task.details?.deliverable;
  const deliverableOptions = getDeliverableOptions(task);

  if (!area || deliverableOptions?.length === 0) {
    return "";
  }

  if (!deliverable) {
    return "";
  }

  let deliverableText = deliverableOptions.find(
    (option) => option.value === deliverable,
  )?.text;

  if (channel && taskOptions[area]?.channels[channel]) {
    deliverableText =
      taskOptions[area].channels[channel]?.text + " | " + deliverableText;
  }

  return deliverableText;
}

export function getGoalText(task) {
  const area = task.details?.area;
  const goal = task.details?.goal;

  if (!area || !goal) {
    return "";
  }

  return taskOptions[area]?.goals[goal]?.text || "";
}

export function getAreaText(task) {
  const area = task.details?.area;

  if (!area) {
    return "";
  }

  return taskOptions[area]?.text || "";
}

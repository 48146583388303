import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import "./Navbar.scss";
import {
  ArrowUturnLeftIcon,
  BookOpenIcon,
  BuildingLibraryIcon,
  FolderIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";
import userSession from "../../utils/userSession";
import { AppContext, navbarOptions } from "../../App";
import ManageClientUsersModal from "../ManageClientUsersModal/ManageClientUsersModal";
import noLogo from "../../assets/no-logo.jpg";
import UserRestrictedArea from "../UserRestrictedArea/UserRestrictedArea";

function ClientNavbar() {
  const { changeNavbar } = useContext(AppContext);
  const client = userSession.getCurrentClient();
  const [clientUsersModalOpenId, setOpenClientUsersModal] = useState(false);

  function openClientUsersModal(clientId) {
    setOpenClientUsersModal(clientId);
  }

  function closeClientUsersModal() {
    setOpenClientUsersModal(false);
  }

  const navigateBackToAgency = () => {
    userSession.removeCurrentClient();
    changeNavbar(navbarOptions.agency);
  };

  return (
    <>
      <ManageClientUsersModal
        isOpen={clientUsersModalOpenId}
        onClose={closeClientUsersModal}
        clientId={clientUsersModalOpenId}
      />
      <div className="navbar">
        <div className="margin-bottom-large client-header">
          <img
            src={client.logo || noLogo}
            className="client-logo margin-bottom-medium margin-top-large"
          />
          <h2>{client.name}</h2>
        </div>

        <nav>
          <ul>
            <li>
              <div className="nav-option">
                <BookOpenIcon className="nav-option-icon" />
                <Link to={`/cliente/${client.id}/manual-marca`}>
                  Manual de Marca
                </Link>
              </div>
            </li>
            <li>
              <div className="nav-option">
                <FolderIcon className="nav-option-icon" />
                <Link to={`/cliente/${client.id}/tarefas`}>Tarefas</Link>
              </div>
            </li>
            <li>
              <div className="nav-option">
                <BuildingLibraryIcon className="nav-option-icon" />
                <Link to={`/cliente/${client.id}/sandbox`}>Área de Testes</Link>
              </div>
            </li>
            <UserRestrictedArea>
              <li>
                <div className="nav-option">
                  <UserGroupIcon className="nav-option-icon" />
                  <Link
                    onClick={() => {
                      openClientUsersModal(client.id);
                    }}
                  >
                    Usuários
                  </Link>
                </div>
              </li>
            </UserRestrictedArea>
          </ul>
        </nav>

        <div className="back-to-agency">
          <hr />
          <div className="action" onClick={navigateBackToAgency}>
            <ArrowUturnLeftIcon className="back-to-agency-icon" />
            <span onClick={navigateBackToAgency}>Voltar para a agência</span>
          </div>
          <hr />
        </div>
      </div>
    </>
  );
}

export default ClientNavbar;

import React from "react";
import "./SidePanel.scss";
import { XMarkIcon } from "@heroicons/react/24/solid";

function SidePanel({ isOpen, children, onClose, className = "" }) {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="sidepanel-overlay" onMouseDown={onClose}>
      <div
        className={`sidepanel-content ${className}`}
        onMouseDown={(e) => e.stopPropagation()}
      >
        <XMarkIcon className="close-button" onClick={onClose} />
        {children}
      </div>
    </div>
  );
}

export default SidePanel;

import {
  BASE_URL,
  apiDefaultErrorHandler,
  httpServiceGet,
  httpServiceCreate,
  httpServiceUpdate,
  httpServiceDelete,
} from "../utils/serviceUtils";

const getClientUsers = async ({ ...props }) => {
  try {
    if (!props.clientId) {
      return;
    }

    const url = new URL(`${BASE_URL}/client_user`);
    const data = await httpServiceGet({ url, ...props });
    return data;
  } catch (error) {
    apiDefaultErrorHandler(error);
  }
};

const createClientUser = async (payload) => {
  try {
    const url = new URL(`${BASE_URL}/client_user`);
    const data = await httpServiceCreate(url, payload);
    return data;
  } catch (error) {
    apiDefaultErrorHandler(error);
  }
};

const updateClientUser = async (id, payload) => {
  try {
    const url = new URL(`${BASE_URL}/client_user/${id}`);
    const data = await httpServiceUpdate(url, payload);
    return data;
  } catch (error) {
    apiDefaultErrorHandler(error);
  }
};

const deleteClientUser = async (id) => {
  try {
    const url = new URL(`${BASE_URL}/client_user/${id}`);
    await httpServiceDelete(url);
    return true;
  } catch (error) {
    apiDefaultErrorHandler(error);
  }
};

const clientUserService = {
  getClientUsers,
  createClientUser,
  updateClientUser,
  deleteClientUser,
};

export default clientUserService;

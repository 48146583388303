import React from "react";
import "./Button.scss";

function Button({
  type = ButtonTypes.primary,
  children,
  onClick,
  className,
  size = ButtonSizes.medium,
  disabled = false,
}) {
  return (
    <button
      onClick={onClick}
      className={`button button-${type} ${className} ${size}`}
      disabled={disabled}
    >
      {children}
    </button>
  );
}

export const ButtonTypes = {
  primary: "primary",
  secondary: "secondary",
  danger: "danger",
  selectInfo: "select-info",
};

export const ButtonSizes = {
  small: "small",
  medium: "medium",
  large: "large",
};

export default Button;

import React from "react";
import "./InfoCard.scss";

function InfoCard({ children, onClick, className = "" }) {
  return (
    <div className={`info-card ${className}`} onClick={onClick}>
      <div className="card-body">{children}</div>
    </div>
  );
}

export default InfoCard;

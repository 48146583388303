import React from "react";
import "./Modal.scss";
import { ExclamationTriangleIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { UserCircleIcon } from "@heroicons/react/24/outline";

function Modal({
  isOpen,
  children,
  onClose,
  title = "",
  subtitle = "",
  Icon = "",
  size = "medium",
  className = "",
}) {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal-overlay" onMouseDown={onClose}>
      <div
        className={`modal-content size-${size} ${className}`}
        onMouseDown={(e) => e.stopPropagation()}
      >
        <div className="modal-header">
          {Icon && <Icon className="modal-icon" />}
          {title && <h1 className="margin-top-small">{title}</h1>}
          {subtitle && <h3 className="margin-top-small">{subtitle}</h3>}
        </div>

        <XMarkIcon className="close-button" onClick={onClose} />
        {children}
      </div>
    </div>
  );
}

export const ModalIcons = {
  warning: ExclamationTriangleIcon,
  user: UserCircleIcon,
};

export const ModalSizes = {
  small: "small",
  medium: "medium",
  large: "large",
  xlarge: "xlarge",
  fitContent: "fit-content",
};

export default Modal;

import React, { useEffect, useState } from "react";
import "./TaskPreview.scss";
import Pill from "../Pill/Pill";
import {
  textInstructions,
  getTaskDeliverableText,
  getGoalText,
  getAreaText,
  noSpokesperson,
  noSection,
  noTargetAudience,
  noProduct,
} from "../../utils/models/taskUtils";
import { getDateFromEpoch } from "../../utils/javascriptUtils";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/solid";
import ItemDetails from "../ItemDetails/ItemDetails";
import SidePanel from "../SidePanel/SidePanel";
import Button, { ButtonTypes } from "../Button/Button";
import CreateOrUpdateTaskModal from "../CreateOrUpdateTaskModal/CreateOrUpdateTaskModal";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import Loader from "../Loader/Loader";
import taskService from "../../services/taskService";
import { brandGuide } from "../../utils/models/brandGuideUtils";

function TaskPreview({ task, className = "" }) {
  const deliverableText = getTaskDeliverableText(task);
  const details = task?.details || {};
  const [showDetails, setShowDetails] = useState(false);

  return (
    <div className={`preview-content ${className}`}>
      <div className="title margin-bottom-medium">
        <h3>Detalhes da tarefa</h3>
      </div>

      <div className="info-columns">
        <div className="task-specifications">
          <div className="info margin-bottom-small">
            <label>Área:</label>

            {details.area ? (
              <>{getAreaText(task)}</>
            ) : (
              "Nenhuma área selecionada"
            )}
          </div>

          <div className="info margin-bottom-small">
            <label>Objetivo:</label>

            {details.goal ? <>{getGoalText(task)}</> : "Não selecionado"}
          </div>

          <div className="info margin-bottom-small">
            <label>Peça:</label> {deliverableText || "Não selecionado"}
          </div>

          {details.textInstructions &&
            Object.entries(task.details.textInstructions).map(
              ([key, value]) =>
                value && (
                  <div key={key} className="info margin-bottom-small">
                    <label>{textInstructions[key]?.text}:</label> {value}
                  </div>
                ),
            )}

          <div className="info margin-bottom-small">
            <label>Editoria:</label>

            {details.section ? (
              <>
                <div>{details.section.name?.value}</div>
                {details.section.id != noSection.id && (
                  <div>
                    <QuestionMarkCircleIcon
                      onMouseOver={() => setShowDetails("section")}
                      onMouseOut={() => setShowDetails(false)}
                      className="icon margin-left-extra-small"
                    />
                    {showDetails === "section" && (
                      <div>
                        <ItemDetails
                          item={details.section}
                          formReference={brandGuide.sections.form}
                          className="position-absolute"
                        />
                      </div>
                    )}
                  </div>
                )}
              </>
            ) : (
              <div>Nenhuma editoria selecionada</div>
            )}
          </div>

          <div className="info margin-bottom-small">
            <label>Produtos ou Serviços mencionados:</label>

            {details.products?.length > 0 ? (
              <div className="info-list">
                {details.products.map((product) => (
                  <div key={product.id} className="info">
                    {product.name?.value}
                    {product.id != noProduct.id && (
                      <div>
                        <QuestionMarkCircleIcon
                          onMouseOver={() => setShowDetails(product.id)}
                          onMouseOut={() => setShowDetails(false)}
                          className="icon margin-left-extra-small margin-right-small"
                        />

                        {showDetails == product.id && (
                          <div>
                            <ItemDetails
                              item={product}
                              formReference={brandGuide.products.form}
                              className="position-absolute"
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            ) : (
              <div>Nenhum produto ou serviço selecionado</div>
            )}
          </div>

          <div className="info margin-bottom-small">
            <label>Públicos Alvo:</label>

            {details.targetAudiences?.length > 0 ? (
              <div className="info-list">
                {details.targetAudiences?.map((targetAudience) => (
                  <div key={targetAudience.id} className="inner-info">
                    {targetAudience.name?.value}
                    {targetAudience.id != noTargetAudience.id && (
                      <div>
                        <QuestionMarkCircleIcon
                          onMouseOver={() => setShowDetails(targetAudience.id)}
                          onMouseOut={() => setShowDetails(false)}
                          className="icon margin-left-extra-small margin-right-small"
                        />

                        {showDetails == targetAudience.id && (
                          <div>
                            <ItemDetails
                              item={targetAudience}
                              formReference={brandGuide.targetAudiences.form}
                              className="position-absolute"
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            ) : (
              <div>Nenhum público alvo selecionado</div>
            )}
          </div>

          <div className="info margin-bottom-small">
            <label>Porta Voz:</label>

            {details.spokesperson ? (
              <>
                <div>{details.spokesperson.name?.value}</div>
                {details.spokesperson.id != noSpokesperson.id && (
                  <div>
                    <QuestionMarkCircleIcon
                      onMouseOver={() => setShowDetails("spokesperson")}
                      onMouseOut={() => setShowDetails(false)}
                      className="icon margin-left-extra-small"
                    />
                    {showDetails === "spokesperson" && (
                      <div>
                        <ItemDetails
                          item={details.spokesperson}
                          formReference={brandGuide.spokespeople.form}
                          className="position-absolute"
                        />
                      </div>
                    )}
                  </div>
                )}
              </>
            ) : (
              <div>Nenhum porta voz selecionado</div>
            )}
          </div>

          <div className="info margin-bottom-small">
            <label>Data de Entrega:</label>
            {task.dueDate ? getDateFromEpoch(task.dueDate) : "Não selecionada"}
          </div>

          {task.tags && (
            <div className="info margin-bottom-small">
              <label>Tags:</label>{" "}
              {task.tags.map((tag) => (
                <Pill key={tag} value={tag} className="margin-left-small" />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export function TaskPreviewSidePanel({
  task,
  isOpen,
  onClose,
  allowDelete = true,
  reloadData,
}) {
  const [editMode, setEditMode] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);
  const [loading, setLoading] = useState(false);

  if (!task?.id) {
    return null;
  }

  async function handleDelete() {
    setLoading(true);
    await taskService.deleteTask(task.id);
    setLoading(false);
    reloadData();
    setDeleteMode(false);
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <CreateOrUpdateTaskModal
        isOpen={editMode}
        onClose={() => setEditMode(false)}
        task={task}
        reloadData={reloadData}
      />
      <ConfirmationModal
        isOpen={deleteMode}
        onClose={() => setDeleteMode(false)}
        title={"Excluir Tarefa"}
        message={"Tem certeza que deseja excluir esta Tarefa?"}
        onConfirm={handleDelete}
      />
      <SidePanel isOpen={isOpen} onClose={onClose}>
        <TaskPreview task={task} />
        <div
          className={`sidepanel-footer margin-top-extra-large ${allowDelete ? "space-between" : "center"} `}
        >
          <Button
            type={ButtonTypes.secondary}
            onClick={(e) => {
              e.stopPropagation();
              setEditMode(true);
              onClose();
            }}
          >
            Editar Detalhes
          </Button>
          {allowDelete && (
            <Button
              type={ButtonTypes.danger}
              onClick={() => {
                setDeleteMode(true);
                onClose();
              }}
            >
              Excluir
            </Button>
          )}
        </div>
      </SidePanel>
    </>
  );
}

export default TaskPreview;

import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/24/solid";
import "./SortComponent.scss";
import React, { useContext } from "react";
import { CrudContext } from "../CrudArea/CrudArea";

function SortComponent({ sort_by }) {
  const { order, setOrder, sort, setSort } = useContext(CrudContext);

  function handleSort() {
    if (sort_by === sort) {
      setOrder(order === "ASC" ? "DESC" : "ASC");
    }
    setSort(sort_by);
  }

  return (
    <div
      className={`sort-component ${sort_by === sort ? "active" : ""}`}
      onClick={handleSort}
    >
      {order === "ASC" ? (
        <ArrowDownIcon className="order-icon" />
      ) : (
        <ArrowUpIcon className="order-icon" />
      )}
    </div>
  );
}

export default SortComponent;

import React from "react";
import "./Pill.scss";

function Pill({ value = "", className = "" }) {
  return value === "" ? null : (
    <span className={`pill ${className}`}>{value}</span>
  );
}

export default Pill;

import React, { useContext, useState } from "react";
import "./CrudArea.scss";
import { EllipsisVerticalIcon, XMarkIcon } from "@heroicons/react/24/solid";
import SortComponent from "../SortComponent/SortComponent";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import { CrudContext } from "./CrudArea";
import userService from "../../services/userService";
import unknownUser from "../../assets/unknown-user.png";
import Button from "../Button/Button";
import { validateEmail } from "../../utils/inputValidations";
import { Input, InputSelect } from "../Input/Input";
import ActionsDropdown from "../ActionsDropdown/ActionsDropdown";
import Loader from "../Loader/Loader";
import userSession from "../../utils/userSession";

export function UsersTable() {
  const {
    data,
    openActionsDropdown,
    closeActionsDropdown,
    openDeleteConfirmationModal,
    closeDeleteConfirmationModal,
    openConfirmDeleteModal,
    actionsDropdownId,
    setData,
    reloadData,
  } = useContext(CrudContext);

  const [idToDelete, setIdToDelete] = useState(null);
  const [loading, setLoading] = useState(false);
  const currentUser = userSession.getCurrentUser();

  async function updateUser(userId, role, active) {
    const payload = {
      role: role,
      active: active,
    };

    setLoading(true);

    const response = await userService.updateUser(userId, payload);

    setLoading(false);

    if (!response) {
      return;
    }

    const updatedUsers = data.users.map((user) => {
      if (user.id === response.id) {
        return response;
      }
      return user;
    });
    setData({ ...data, users: updatedUsers });
  }

  async function handleDelete() {
    setLoading(true);
    await userService.deleteUser(idToDelete);
    setLoading(false);
    reloadData();
    closeDeleteConfirmationModal();
  }

  function getOptionsForUser(user) {
    const options = [
      {
        name: "Excluir",
        onClick: () => {
          setIdToDelete(user.id);
          openDeleteConfirmationModal();
        },
      },
    ];

    return options;
  }

  async function resendEmail(userId) {
    setLoading(true);
    await userService.resendEmail(userId);
    setLoading(false);
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <ConfirmationModal
        isOpen={openConfirmDeleteModal}
        onClose={closeDeleteConfirmationModal}
        title={"Excluir Usuário"}
        message={"Tem certeza que deseja excluir este usuário?"}
        onConfirm={handleDelete}
      />

      <table>
        <thead>
          <tr>
            <th className="column-1"></th>
            <th className="column-4">
              Nome <SortComponent sort_by="name" />
            </th>
            <th className="column-4">
              Email <SortComponent sort_by="email" />
            </th>
            <th className="column-2">
              Função do usuário <SortComponent sort_by="role" />
            </th>
            <th className="column-1">
              Status <SortComponent sort_by="status" />
            </th>
            <th className="column-1"></th>
          </tr>
        </thead>
        <tbody>
          {data.users?.map((user, userIdx) => {
            return (
              <tr key={user.id}>
                <td className="column-1">
                  <img src={user.photo || unknownUser} className="logo" />
                </td>
                <td className="column-4">
                  {user.isPending ? (
                    <i>Aguardando cadastro (usuário pendente)</i>
                  ) : (
                    user.name
                  )}
                </td>
                <td className="column-4">{user.email}</td>
                <td className="column-2">
                  {currentUser.id === user.id ? (
                    <>{user.role === "admin" ? "Administrador" : "Usuário"}</>
                  ) : (
                    <>
                      <InputSelect
                        value={user.role}
                        onChange={(value) => {
                          updateUser(user.id, value, user.active);
                        }}
                        className="access-column"
                        options={[
                          { value: "user", label: "Usuário" },
                          { value: "admin", label: "Administrador" },
                        ]}
                      />
                    </>
                  )}
                </td>

                <td className="column-1">
                  {user.isPending ? (
                    <div className="pending-status">
                      <div>Pendente</div>
                      <a
                        className="font-size-smallest"
                        onClick={() => resendEmail(user.id)}
                      >
                        Reeviar Convite
                      </a>
                    </div>
                  ) : user.active ? (
                    "Ativo"
                  ) : (
                    "Inativo"
                  )}
                </td>
                <td className="column-1 align-right">
                  {currentUser.id !== user.id && (
                    <>
                      <EllipsisVerticalIcon
                        className="crud-item-action"
                        onClick={() => openActionsDropdown(userIdx)}
                      />
                      {actionsDropdownId === userIdx && (
                        <ActionsDropdown
                          closeActionsDropdown={closeActionsDropdown}
                          options={getOptionsForUser(user)}
                        />
                      )}
                    </>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

export function UserModalContent() {
  const { closeCrudModal, reloadData } = useContext(CrudContext);
  const [users, setUsers] = useState([{ email: "", role: "user" }]);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  function addUserToForm() {
    setUsers([...users, { email: "", role: "user" }]);
  }

  function removeUserFromForm(idx) {
    const updatedUsers = users.filter((user, i) => i !== idx);
    setUsers(updatedUsers);
  }

  function setUserEmail(idx, email) {
    const updatedUsers = users.map((user, i) => {
      if (i === idx) {
        return { ...user, email };
      }
      return user;
    });
    setUsers(updatedUsers);
  }

  function setUserRole(idx, role) {
    const updatedUsers = users.map((user, i) => {
      if (i === idx) {
        return { ...user, role };
      }
      return user;
    });
    setUsers(updatedUsers);
  }

  function validateFormInputs() {
    const invalidEmails = users.reduce((acc, user, idx) => {
      if (!validateEmail(user.email).isValid) {
        acc.push(idx);
      }
      return acc;
    }, []);

    if (invalidEmails.length > 0) {
      return false;
    }

    return true;
  }

  async function handleSave() {
    setFormSubmitted(true);
    const formIsValid = validateFormInputs();

    if (!formIsValid) {
      return;
    }

    const payload = {
      users: users.filter((user) => user.email !== ""),
    };

    setLoading(true);

    const response = await userService.createUser(payload);

    setLoading(false);

    if (!response) {
      return;
    }

    reloadData();
    closeCrudModal();
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="crud-form">
      <div className="form-content-user margin-top-medium">
        <div className="form-labels">
          <div className="email-column">Endereço de email</div>
          <div className="access-column">Nível de Acesso </div>
          <div className="close-column"> </div>
        </div>

        {users.map((user, idx) => (
          <div className="input-line margin-top-small" key={idx}>
            <div className="email-column">
              <Input
                type="text"
                placeholder="Email"
                value={user.email}
                onChange={(value) => {
                  setUserEmail(idx, value);
                }}
                validationError={
                  formSubmitted &&
                  !validateEmail(user.email).isValid &&
                  "Email inválido"
                }
              />
            </div>
            <div className="access-column">
              <InputSelect
                value={user.role}
                onChange={(value) => {
                  setUserRole(idx, value);
                }}
                options={[
                  { value: "user", label: "Usuário" },
                  { value: "admin", label: "Administrador" },
                ]}
                validationError=""
              />
            </div>
            <div className="close-column">
              {idx > 0 && (
                <div className="action">
                  <XMarkIcon
                    className="icon-small margin-top-small"
                    onClick={() => {
                      removeUserFromForm(idx);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        ))}

        <Button
          type="secondary"
          className="margin-top-small"
          onClick={addUserToForm}
        >
          + Adicionar outro
        </Button>
      </div>

      <div className="form-footer">
        <Button type="secondary" onClick={closeCrudModal} className="width-48">
          Cancelar
        </Button>
        <Button type="primary" onClick={handleSave} className="width-48">
          Enviar Convites
        </Button>
      </div>
    </div>
  );
}

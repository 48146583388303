import React from "react";
import "./AgentsPage.scss";
import PageTitle from "../../components/PageTitle/PageTitle";

function AgentsPage() {
  return (
    <div className="page">
      <PageTitle
        title="Agentes"
        description="Gerencie os Agentes que trabalham na sua Agência"
        className={"margin-bottom-extra-large"}
      />
      <div className="em-breve">
        <h2>EM BREVE</h2>
        <br />
        <br />
        <p>- Visualize e adquira novos agentes para a sua agência</p>
        <p>- Visualize e adquira skills para os seus agentes</p>
        <p>
          - Veja estatísticas de cada agente (trabalhos entergues, % de acerto,
          feedback dos usuários, logs etc)
        </p>
        <p>- Converse com o Agente</p>
      </div>
    </div>
  );
}

export default AgentsPage;

import React, { useState } from "react";
import "./Input.scss";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ptBR } from "date-fns/locale";
import "react-image-crop/dist/ReactCrop.css";

registerLocale("ptBR", ptBR);

export function Input({
  label = "",
  type,
  value = "",
  onChange,
  validationError = "",
  placeholder = "",
  className = "",
  disabled = false,
  onEnter = false,
}) {
  return (
    <div className={`input-group ${className}`}>
      {label && <label>{label}</label>}
      {type === "textarea" ? (
        <textarea
          required
          onChange={(e) => onChange(e.target.value)}
          value={value || ""}
          placeholder={placeholder}
          disabled={disabled}
        />
      ) : (
        <input
          type={type}
          required
          onChange={(e) => onChange(e.target.value)}
          value={value || ""}
          placeholder={placeholder}
          disabled={disabled}
          onKeyDown={(event) => {
            if (onEnter && event.key === "Enter") {
              onEnter();
            }
          }}
        />
      )}
      {validationError && (
        <p className="input-validation-error">{validationError}</p>
      )}
    </div>
  );
}

export function InputCheckbox({
  label = "",
  value = false,
  onChange,
  validationError = "",
  className = "",
}) {
  return (
    <div className={`${className}`}>
      <label>{label}</label>
      <input
        type="checkbox"
        required
        onChange={(e) => onChange(e.target.checked)}
        checked={value}
      />
      {validationError && (
        <p className="input-validation-error">{validationError}</p>
      )}
    </div>
  );
}

export function InputSelect({
  label = "",
  value = "",
  onChange,
  options = [],
  validationError = "",
  className = "",
}) {
  return (
    <div className={`input-group ${className}`}>
      {label && <label>{label}</label>}
      <select required onChange={(e) => onChange(e.target.value)} value={value}>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {validationError && (
        <p className="input-validation-error">{validationError}</p>
      )}
    </div>
  );
}

export function InputPhotoUploader({
  onChange,
  value,
  validationError = "",
  className,
  type = ImageUploadTypes.user,
}) {
  return (
    <>
      <div
        className={`upload-area type-${type} ${value ? "hide" : ""} ${className}`}
        onClick={() =>
          document.querySelector('.upload-area input[type="file"]').click()
        }
      >
        <input
          type="file"
          accept="image/*"
          style={{ display: "none" }}
          onChange={(event) => {
            onChange(event.target.files[0]);
          }}
        />
      </div>
      {value && (
        <img
          src={value instanceof Blob ? URL.createObjectURL(value) : value}
          className={`photo-preview ${className}`}
          onClick={() =>
            document.querySelector('.upload-area input[type="file"]').click()
          }
        />
      )}
      {validationError && (
        <p className="input-validation-error">{validationError}</p>
      )}
    </>
  );
}

export const ImageUploadTypes = {
  user: "user",
  logo: "logo",
};

export function InputDatePicker({
  label = "",
  value = "",
  onChange,
  validationError = "",
  className = "",
}) {
  return (
    <div className={`input-group ${className}`}>
      {label && <label>{label}</label>}
      <DatePicker
        selected={value}
        onChange={onChange}
        locale="ptBR"
        className="date-picker"
      />
      {validationError && (
        <p className="input-validation-error">{validationError}</p>
      )}
    </div>
  );
}

export function InputDropdownSingle({
  label = "",
  value = "",
  onChange,
  validationError = "",
  className = "",
  options = [],
  placeholder = "Selecione uma opção",
  disabled = false,
}) {
  return (
    <div className={`input-group ${className}`}>
      {label && <label>{label}</label>}
      <select
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className="dropdown-select"
        disabled={disabled}
      >
        <option value="" hidden>
          {placeholder}
        </option>
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label || option.text}
          </option>
        ))}
      </select>
      {validationError && (
        <p className="input-validation-error">{validationError}</p>
      )}
    </div>
  );
}
export const MultiSelectDropdown = ({
  options,
  onChange,
  placeholder = "Selecione as opções que deseja",
  className = "",
  emptyOption = "Nenhuma das opções",
  label = "",
  disabled = false, // Added disabled prop
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const handleToggleOption = (option) => {
    let updatedOptions;
    if (option === "") {
      updatedOptions = [];
    } else {
      updatedOptions = selectedOptions.includes(option)
        ? selectedOptions.filter((o) => o !== option)
        : [...selectedOptions, option];
    }

    setSelectedOptions(updatedOptions);
    onChange(updatedOptions);
  };

  const selectedValues = selectedOptions.map((option) => {
    const selectedOption = options.find((o) => o.value === option);
    return selectedOption ? selectedOption.label : "";
  });

  const displayValue =
    selectedValues.length > 0 ? selectedValues.join(", ") : placeholder;

  const handleDropdownClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`multi-select-dropdown ${className}`}>
      {label && <label>{label}</label>}
      <button
        className="dropdown-button"
        onClick={handleDropdownClick}
        disabled={disabled}
      >
        {displayValue}
      </button>
      {isOpen && (
        <div className="dropdown-content">
          <label key="" className="dropdown-item">
            <input
              type="checkbox"
              checked={selectedOptions.length === 0}
              onChange={() => handleToggleOption("")}
              disabled={disabled}
            />
            {emptyOption}
          </label>
          {options.map((option) => (
            <label key={option} className="dropdown-item">
              <input
                type="checkbox"
                checked={selectedOptions.includes(option.value)}
                onChange={() => handleToggleOption(option.value)}
                disabled={disabled}
              />
              {option.label}
            </label>
          ))}
        </div>
      )}
    </div>
  );
};

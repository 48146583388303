import React, { useState, useEffect } from "react";
import "./Loader.scss";

function Loader() {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(true);
    }, 500);
    return () => clearTimeout(timer); // Limpa o timer se o componente for desmontado
  }, []);

  if (!show) {
    return null;
  }

  return (
    <div className="loader-container">
      <span className="loader"></span>
    </div>
  );
}

export default Loader;

import { camelCase, snakeCase, mapKeys } from "lodash";

export const mapKeysToSnakeCase = (data) => {
  return mapKeys(data, (value, key) => snakeCase(key));
};

export const mapKeysToCamelCase = (data) => {
  return mapKeys(data, (value, key) => camelCase(key));
};

export function pluralize(word) {
  // Check if the word ends with "s" or "z"
  if (word.endsWith("s") || word.endsWith("z") || word.endsWith("r")) {
    return word + "es";
  }

  // Check if the word ends with "ão"
  if (word.endsWith("ão")) {
    return word.slice(0, -2) + "ões";
  }

  // Check if the word ends with "l" and has a vowel before it
  if (word.endsWith("l") && /[aeiouáéíóú]/.test(word.charAt(word.length - 2))) {
    return word.slice(0, -1) + "is";
  }

  // Default case: add "s" to the end of the word
  return word + "s";
}

export function getParamsFromUrl(url) {
  const params = {};
  const queryString = url.split("?")[1];
  if (queryString) {
    const keyValuePairs = queryString.split("&");
    keyValuePairs.forEach((pair) => {
      const [key, value] = pair.split("=");
      params[key] = value;
    });
  }
  return params;
}

export function addParamToUrl(param, value) {
  let url = new URL(window.location.href);
  url.searchParams.set(param, value);
  window.history.pushState({}, "", url);
}

export function getSubdomain(url) {
  const urlObject = new URL(url);
  const parts = urlObject.hostname.split(".");
  if (parts.length > 2) {
    return parts[0];
  }
  return "";
}

export function containsLocalhost(url) {
  return url.includes("localhost");
}

export function getDateFromEpoch(epoch) {
  if (!epoch) {
    return "";
  }
  return new Date(epoch * 1000).toLocaleDateString();
}

export function getDatetimeFromEpoch(epoch) {
  if (!epoch) {
    return "";
  }
  return new Date(epoch * 1000).toLocaleString("pt-BR", {
    dateStyle: "short",
    timeStyle: "short",
  });
}

export function convertDateToEpoch(date) {
  if (!date) {
    return "";
  }
  return new Date(date).getTime() / 1000;
}

export function validateEmail(email) {
  if (!email) {
    return { isValid: false, errorMessage: "Email é obrigatório" };
  }

  const emailRegex = /^[a-z0-9.]+@[a-z0-9.]+\.[a-z.]+$/i;

  if (!emailRegex.test(email)) {
    return { isValid: false, errorMessage: "Email inválido" };
  }

  return { isValid: true, errorMessage: "" };
}

export function validatePassword(password) {
  if (!password) {
    return { isValid: false, errorMessage: "Senha é obrigatória" };
  }

  if (password.length < 8) {
    return {
      isValid: false,
      errorMessage: "A senha deve ter no mínimo 8 caracteres",
    };
  }

  const uppercaseRegex = /[A-Z]/;
  const numberRegex = /[0-9]/;
  const specialCharRegex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;

  if (
    !uppercaseRegex.test(password) ||
    !numberRegex.test(password) ||
    !specialCharRegex.test(password)
  ) {
    return {
      isValid: false,
      errorMessage:
        "A senha deve conter ao menos uma letra maiúscula, um número e um caractere especial",
    };
  }

  return { isValid: true, errorMessage: "" };
}
export function validateNonEmptyField(value) {
  if (!value) {
    return { isValid: false, errorMessage: "Este campo é obrigatório" };
  }
  return { isValid: true, errorMessage: "" };
}

import React, { useEffect, useState } from "react";
import "./ClientBrandGuidePage.scss";
import PageTitle from "../../components/PageTitle/PageTitle";
import { Input } from "../../components/Input/Input";
import Button, { ButtonTypes } from "../../components/Button/Button";
import emptyState from "../../assets/empty-state.svg";
import { v4 as uuidv4 } from "uuid";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";
import brandGuideService from "../../services/brandGuideService";
import userSession from "../../utils/userSession";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { addParamToUrl, getParamsFromUrl } from "../../utils/javascriptUtils";
import Loader from "../../components/Loader/Loader";
import { brandGuide } from "../../utils/models/brandGuideUtils";
import SidePanel from "../../components/SidePanel/SidePanel";
import AgentChatWindow, {
  authors,
} from "../../components/AgentChatWindow/AgentChatWindow";
import copyAgentService from "../../services/copyAgentService";

function ClientBrandGuidePage() {
  return (
    <div className="page">
      <PageTitle
        title="Manual de Marca"
        description="Treine seus agentes para que eles conheçam tudo sobre sua marca"
        className={"margin-bottom-extra-large"}
      />

      <BrandGuideComponent />
    </div>
  );
}

function BrandGuideComponent() {
  const [data, setData] = useState({});
  const [previousConversation, updatePreviousConversation] = useState(null);

  const tabs = Object.keys(brandGuide).reduce((brandGuideTab, key) => {
    brandGuideTab[key] = brandGuide[key].value;
    return brandGuideTab;
  }, {});

  const tab = getParamsFromUrl(window.location.search).tab;
  const initialTab = tab ? Object.values(tabs).indexOf(tab) : 0;
  const [activeTab, setActiveTab] = useState(initialTab);
  const [openAgent, setOpenAgent] = useState(false);

  useEffect(() => {
    if (tab) {
      const tabIdx = Object.values(tabs).indexOf(tab);
      setActiveTab(tabIdx);
    }
  }, [tab]);

  if (!tab || tab != Object.values(tabs)[activeTab]) {
    addParamToUrl("tab", Object.values(tabs)[activeTab]);
  }

  return (
    <>
      <SidePanel isOpen={openAgent} onClose={() => setOpenAgent(false)}>
        <AgentChatWindow
          agentService={copyAgentService.brandGuideReview}
          startedConversation={previousConversation ? true : false}
          previousConversation={previousConversation}
          updatePreviousConversation={updatePreviousConversation}
          s
        />
      </SidePanel>
      <div className="brand-guide">
        <div className="left-area">
          <div className="tabs">
            {Object.keys(tabs).map((tab, idx) => (
              <div
                key={idx}
                className={`tab ${activeTab === idx ? "active" : ""}`}
                onClick={() => setActiveTab(idx)}
              >
                {brandGuide[tabs[tab]].name}
              </div>
            ))}
          </div>
          <Button className="margin-top-large" onClick={setOpenAgent}>
            Falar com o Agente de IA
          </Button>
        </div>
        <div className="tab-content">
          <BrandForm
            data={data}
            setData={setData}
            currentTab={Object.values(tabs)[activeTab]}
          />
        </div>
      </div>
    </>
  );
}

function BrandForm({ data, setData, currentTab }) {
  const tabForm = brandGuide[currentTab];
  const [loading, setLoading] = useState(false);

  if (!tabForm) {
    return null;
  }

  useEffect(() => {
    async function fetchData() {
      setLoading(true);

      const result = await brandGuideService.getBrandGuide({
        clientId: userSession.getCurrentClient().id,
        type: tabForm.value,
      });

      setLoading(false);

      if (!result?.content) {
        return;
      }

      setData(result.content);
    }
    fetchData();
  }, [currentTab]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="brand-form">
      <h2 className="margin-bottom-medium">{tabForm.name}</h2>
      {tabForm.registerMultiples ? (
        <BrandMultipleForm tabForm={tabForm} data={data} setData={setData} />
      ) : (
        <BrandSingleForm tabForm={tabForm} data={data} setData={setData} />
      )}
    </div>
  );
}

function BrandSingleForm({ tabForm, data, setData }) {
  const [loading, setLoading] = useState(false);

  async function saveForm() {
    setLoading(true);
    await brandGuideService.upsertBrandGuide({
      type: tabForm.value,
      content: data,
      clientId: userSession.getCurrentClient().id,
    });
    setLoading(false);
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      {tabForm.form?.map((input, idx) => (
        <div key={idx} className="form-input margin-bottom-medium">
          <Input
            label={input.label}
            type={input.type}
            value={data?.[input.value]?.value || ""}
            placeholder={input.placeholder}
            onChange={(value) =>
              setData({
                ...data,
                [input.value]: { value: value, label: input.value },
              })
            }
          />
        </div>
      ))}
      <div className="form-buttons">
        <Button onClick={saveForm}>Salvar</Button>
      </div>
    </>
  );
}

function BrandMultipleForm({ tabForm, data, setData }) {
  const currentValueArray = data?.[tabForm.value] || [];
  const [newForm, setNewForm] = useState({});
  const [openNewForm, setOpenNewForm] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [itemToRemove, setItemToRemove] = useState(null);
  const [loading, setLoading] = useState(false);

  async function saveForm() {
    if (!newForm?.name?.value) {
      alert("Nome é obrigatório");
      return;
    }

    let updatedData;

    if (editMode) {
      const updatedArray = currentValueArray.map((item) => {
        if (item.id === newForm.id) {
          return newForm;
        }
        return item;
      });
      updatedData = { ...data, [tabForm.value]: updatedArray };
    } else {
      updatedData = {
        ...data,
        [tabForm.value]: [...currentValueArray, { id: uuidv4(), ...newForm }],
      };
    }

    setLoading(true);

    const result = await brandGuideService.upsertBrandGuide({
      type: tabForm.value,
      content: updatedData,
      clientId: userSession.getCurrentClient().id,
    });

    setLoading(false);

    if (!result) {
      return;
    }

    setData(updatedData);
    setOpenNewForm(false);
    setNewForm({});
    setEditMode(false);
  }
  function addNewInputFromForm(input, value) {
    setNewForm({
      ...newForm,
      [input.value]: { value: value, label: input.value },
    });
  }

  function cancelForm() {
    setOpenNewForm(false);
    setNewForm({});
    setEditMode(false);
  }

  function updateItem(item) {
    setNewForm(item);
    setOpenNewForm(true);
    setEditMode(true);
  }

  async function removeItem(item) {
    const updatedArray = currentValueArray.filter((i) => i.id !== item.id);
    const updatedData = { ...data, [tabForm.value]: updatedArray };

    setLoading(true);

    const result = await brandGuideService.upsertBrandGuide({
      type: tabForm.value,
      content: updatedData,
      clientId: userSession.getCurrentClient().id,
    });

    setLoading(false);

    if (!result) {
      return;
    }

    setData(updatedData);
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      {itemToRemove && (
        <ConfirmationModal
          isOpen={itemToRemove}
          onClose={() => setItemToRemove(null)}
          title={`Excluindo '${itemToRemove.name.value}'`}
          message={"Tem certeza que deseja prosseguir?"}
          onConfirm={() => {
            removeItem(itemToRemove);
            setItemToRemove(null);
          }}
        />
      )}

      <table className="primary margin-top-medium">
        <thead>
          <tr>
            <th className="column-8">Nome</th>
            <th className="column-4"></th>
          </tr>
        </thead>
        <tbody>
          {!currentValueArray?.length ? (
            <div className="empty-table">
              <img src={emptyState} alt="Logo" />
            </div>
          ) : (
            currentValueArray?.map((item, idx) => {
              return (
                <tr
                  key={idx}
                  className={item.id == newForm.id ? "editing-row" : ""}
                >
                  <td className="column-8">{item.name.value}</td>
                  <td className="column-4 align-right">
                    <Button
                      type={ButtonTypes.secondary}
                      onClick={() => updateItem(item)}
                    >
                      Editar
                    </Button>
                    <XMarkIcon
                      onClick={() => setItemToRemove(item)}
                      className="icon margin-left-medium"
                    />
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </table>

      <div className="margin-top-medium">
        {!openNewForm ? (
          <div className="new-form">
            <Button
              onClick={() => {
                setOpenNewForm(true);
                setEditMode(false);
              }}
            >
              Adicionar Novo
            </Button>
          </div>
        ) : (
          <>
            <h3 className="margin-bottom-small">
              {editMode ? "Editar" : "Adicionar Novo"}
            </h3>
            {tabForm.form?.map((input, idx) => (
              <div key={idx} className="form-input margin-bottom-medium">
                <Input
                  label={input.label}
                  type={input.type}
                  value={newForm?.[input.value]?.value || ""}
                  placeholder={input.placeholder}
                  onChange={(value) => addNewInputFromForm(input, value)}
                />
              </div>
            ))}
            <div className="form-buttons">
              <Button onClick={cancelForm} type={ButtonTypes.secondary}>
                Cancelar
              </Button>
              <Button onClick={saveForm}>Salvar</Button>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default ClientBrandGuidePage;

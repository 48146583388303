import React, {
  forwardRef,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import Quill from "quill";
import "./QuillTextEditor.scss";
import "quill/dist/quill.snow.css";
import { PaperAirplaneIcon, SparklesIcon } from "@heroicons/react/24/solid";
import { Input } from "../Input/Input";
import Button, { ButtonSizes, ButtonTypes } from "../Button/Button";

const defaultModules = {
  toolbar: {
    container: [
      "bold",
      "italic",
      "underline",
      { size: ["small", false, "large", "huge"] },
    ],
  },
};

const QuillTextEditor = forwardRef(
  (
    {
      readOnly,
      defaultValue,
      onTextChange,
      onSelectionChange,
      className,
      modules = defaultModules,
      theme = "snow",
      enableAIHelper = false,
      modifyTextWithAI,
      AIinsertTextFunction,
    },
    ref,
  ) => {
    const containerRef = useRef(null);
    const defaultValueRef = useRef(defaultValue);
    const onTextChangeRef = useRef(onTextChange);
    const onSelectionChangeRef = useRef(onSelectionChange);
    const [selectedText, setSelectedText] = useState("");
    const [enableModifyTextFeature, setEnableModifyTextFeature] =
      useState(false);
    const [enableInsertTextFeature, setEnableInsertTextFeature] =
      useState(false);
    const [showAIGenInput, setShowAIGenInput] = useState(false);
    const [showAIActionsMenu, setShowAIActionsMenu] = useState(false);
    const [confirmAction, setConfirmAction] = useState(false);
    const [selectionPosition, setSelectionPosition] = useState({
      top: 0,
      left: 0,
      selection: null,
    });
    const [currentIndex, setCurrentIndex] = useState(0);

    useLayoutEffect(() => {
      onTextChangeRef.current = onTextChange;
      onSelectionChangeRef.current = onSelectionChange;
    });

    useEffect(() => {
      ref.current?.enable(!readOnly);
    }, [ref, readOnly]);

    useEffect(() => {
      const container = containerRef.current;
      const editorContainer = container.appendChild(
        container.ownerDocument.createElement("div"),
      );
      const quill = new Quill(editorContainer, {
        theme: theme,
        modules: modules,
      });

      ref.current = quill;

      if (defaultValueRef.current) {
        quill.setContents(defaultValueRef.current);
      }

      quill.on(Quill.events.TEXT_CHANGE, (delta, oldDelta, source) => {
        const { index: cursorIndex = -1 } = quill?.getSelection() || {};
        setCurrentIndex(cursorIndex);

        if (source === "user") {
          delta.ops.forEach((op) => {
            if (op.delete) {
              setEnableModifyTextFeature(false);
              setEnableInsertTextFeature(true);
            }
          });
        }
        onTextChangeRef.current?.(delta, oldDelta, source);
      });

      quill.on(Quill.events.SELECTION_CHANGE, (...args) => {
        onSelectionChangeRef.current?.(...args);
        if (args[0]) {
          if (args[0].length > 0) {
            const text = quill.getText(args[0].index, args[0].length);
            const bounds = quill.getBounds(args[0].index);
            const selection = quill.getSelection();

            setSelectedText(text);
            setEnableModifyTextFeature(true);
            setEnableInsertTextFeature(false);
            setShowAIActionsMenu(false);
            setShowAIGenInput(false);
            setConfirmAction(false);
            setSelectionPosition({
              top: bounds.top,
              left: bounds.left,
              selection: selection,
            });
          } else {
            const indexClicked = quill.getSelection().index;

            setEnableModifyTextFeature(false);
            setEnableInsertTextFeature(true);
            setShowAIGenInput(false);
            setCurrentIndex(indexClicked);
          }
        } else {
          setEnableModifyTextFeature(false);
          setEnableInsertTextFeature(false);
        }
      });

      return () => {
        ref.current = null;
        container.innerHTML = "";
      };
    }, [ref]);

    useEffect(() => {
      if (ref.current && defaultValue !== defaultValueRef.current) {
        defaultValueRef.current = defaultValue;
        ref.current.setContents(defaultValue);
      }
    }, [defaultValue]);

    return (
      <>
        <div ref={containerRef} className={`${className}`}></div>
        {enableAIHelper &&
          AIInserTextComponent({
            AIinsertTextFunction,
            currentIndex,
            showAIGenInput,
            setShowAIGenInput,
            enableInsertTextFeature,
            ref,
          })}
        {enableAIHelper &&
          AIModifyTextComponent({
            enableModifyTextFeature,
            modifyTextWithAI,
            setShowAIActionsMenu,
            selectedText,
            selectionPosition,
            setEnableModifyTextFeature,
            setConfirmAction,
            showAIActionsMenu,
            confirmAction,
            ref,
          })}
      </>
    );
  },
);

function AIModifyTextComponent({
  enableModifyTextFeature,
  modifyTextWithAI,
  setShowAIActionsMenu,
  selectedText,
  selectionPosition,
  setEnableModifyTextFeature,
  setConfirmAction,
  showAIActionsMenu,
  confirmAction,
  ref,
}) {
  const [loading, setLoading] = useState(false);
  const [textToReplace, setTextToReplace] = useState("");
  const [customAction, setCustomAction] = useState("");

  if (!enableModifyTextFeature) {
    return null;
  }

  async function modifyWithAgent(action) {
    setShowAIActionsMenu(false);
    setLoading(true);
    setConfirmAction(true);

    const result = await modifyTextWithAI(selectedText, action);

    setLoading(false);
    setCustomAction("");

    if (!result?.answer) {
      setConfirmAction(false);
      return;
    }

    setTextToReplace(result.answer);
  }

  function confirmModification() {
    setEnableModifyTextFeature(false);
    const quill = ref.current;
    const selection = selectionPosition.selection;
    if (selection) {
      quill.deleteText(selection.index, selection.length);
      quill.insertText(selection.index, textToReplace);
    }
  }

  return (
    <>
      <div
        className="ai-icon-container"
        style={{
          position: "absolute",
          top: selectionPosition.top,
          left: selectionPosition.left,
        }}
        onClick={(e) => {
          e.stopPropagation();
          setShowAIActionsMenu(true);
        }}
        onMouseDown={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <SparklesIcon className="text-ai-icon" />
      </div>
      {showAIActionsMenu && (
        <div
          className="ai-actions-menu"
          style={{
            position: "absolute",
            top: selectionPosition.top + 40,
            left: selectionPosition.left,
            zIndex: 1000,
          }}
        >
          <div
            className="ai-actions-menu-item"
            onClick={() =>
              modifyWithAgent("Reescreva este trecho do texto, melhorando ele.")
            }
            onMouseDown={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            Melhorar Texto
          </div>
          <div
            className="ai-actions-menu-item"
            onClick={() =>
              modifyWithAgent("Resuma este trecho do texto em menos palavras.")
            }
            onMouseDown={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            Resumir
          </div>
          <div
            className="ai-actions-menu-item"
            onClick={() =>
              modifyWithAgent(
                "Expanda este trecho do texto, reescrevendo-o com mais palavras.",
              )
            }
            onMouseDown={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            Expandir Texto
          </div>
          <div
            className="ai-actions-menu-custom"
            onMouseUp={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <Input
              placeholder="Outra ação (explique)"
              className="width-90"
              value={customAction}
              onChange={setCustomAction}
              onEnter={() => modifyWithAgent(customAction)}
            />
            <PaperAirplaneIcon
              className="send-action-icon"
              onClick={() => modifyWithAgent(customAction)}
              onMouseDown={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            />
          </div>
        </div>
      )}
      {confirmAction && (
        <div
          className="confirm-action-container"
          style={{
            position: "absolute",
            top: selectionPosition.top + 40,
            left: selectionPosition.left,
          }}
          onMouseUp={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <div className="confirm-action-text">
            {loading ? (
              <span className="loader"></span>
            ) : (
              <div>
                <p>
                  <b>Deseja substituir o texto selecionado por:</b>
                </p>
                {textToReplace}
              </div>
            )}
          </div>
          {!loading && (
            <div className="confirm-action-buttons margin-top-medium">
              <Button
                onClick={() => {
                  setConfirmAction(false);
                }}
                type={ButtonTypes.secondary}
                size={ButtonSizes.small}
                className="margin-right-small full-width"
              >
                Cancelar
              </Button>
              <Button
                onClick={confirmModification}
                type={ButtonTypes.secondary}
                size={ButtonSizes.small}
                className="full-width"
              >
                Aceitar
              </Button>
            </div>
          )}
        </div>
      )}
    </>
  );
}
function AIInserTextComponent({
  AIinsertTextFunction,
  currentIndex,
  showAIGenInput,
  setShowAIGenInput,
  enableInsertTextFeature,
  ref,
}) {
  const [loading, setLoading] = useState(false);
  const [customAction, setCustomAction] = useState("");
  const [generatedText, setGeneratedText] = useState("");

  if (!enableInsertTextFeature) {
    return null;
  }

  async function generateTextWithAgent(action) {
    setLoading(true);

    const result = await AIinsertTextFunction(currentIndex, action);

    setLoading(false);

    if (!result?.answer) {
      return;
    }

    setGeneratedText(result.answer);
  }

  function confirmInsertion() {
    const quill = ref.current;
    quill.insertText(currentIndex, generatedText);
    setCustomAction("");
    setGeneratedText("");
    setShowAIGenInput(false);
  }

  function cancelModification() {
    setShowAIGenInput(false);
  }

  return (
    <>
      <div
        className="ai-icon-container"
        style={{
          position: "absolute",
          top: 4,
          right: 5,
          zIndex: 1000,
        }}
        onClick={(e) => {
          e.stopPropagation();
          setShowAIGenInput(true);
        }}
        onMouseDown={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <SparklesIcon className="text-ai-icon" />
      </div>
      {showAIGenInput && (
        <>
          <div
            className="ai-actions-menu large"
            style={{
              position: "absolute",
              top: 40,
              right: 0,
            }}
          >
            {generatedText && (
              <>
                <div className="generated-text ">{generatedText}</div>
                <div
                  onMouseUp={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  <Button
                    onClick={confirmInsertion}
                    type={ButtonTypes.secondary}
                    size={ButtonSizes.small}
                    className="full-width"
                  >
                    Aceitar
                  </Button>
                  <Button
                    onClick={cancelModification}
                    type={ButtonTypes.secondary}
                    size={ButtonSizes.small}
                    className="full-width"
                  >
                    Cancelar
                  </Button>
                </div>
              </>
            )}
            {loading ? (
              <div className="loading-container">
                <span className="loader"></span>
              </div>
            ) : (
              <div
                className="ai-actions-menu-custom"
                onMouseUp={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <Input
                  placeholder="O que você quer escrever?"
                  className="width-90"
                  value={customAction}
                  onChange={setCustomAction}
                  onEnter={() => generateTextWithAgent(customAction)}
                />
                <PaperAirplaneIcon
                  className="send-action-icon"
                  onClick={() => generateTextWithAgent(customAction)}
                  onMouseDown={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
}

QuillTextEditor.displayName = "QuillTextEditor";

export default QuillTextEditor;

import React from "react";
import "./AgencyDashboardPage.scss";

function AgencyDashboardPage() {
  return (
    <div className="agency-dashboard-page">
      <h1>Dashboard</h1>
      <h2>Relatórios e dados que os gestores gostariam de ver</h2>
      <div>Resultados gerais de cada cliente (quais?)</div>
      <div>Desempenho dos funcionários</div>
      <div>O que você gostaria de ver como gestor(a)...?</div>
    </div>
  );
}

export default AgencyDashboardPage;

import React, { useEffect, useState } from "react";
import "./ResetPasswordPage.scss";
import { validateEmail, validatePassword } from "../../utils/inputValidations";
import Card from "../../components/Card/Card";
import Button, { ButtonSizes } from "../../components/Button/Button";
import { Input } from "../../components/Input/Input";
import { getParamsFromUrl } from "../../utils/javascriptUtils";
import userService from "../../services/userService";
import { customSuccessToast } from "../../utils/serviceUtils";

function ResetPasswordPage() {
  const [invalidToken, setInvalidToken] = useState(false);
  const urlParams = getParamsFromUrl(window.location.search);
  const token = urlParams.token;

  useEffect(() => {
    async function verifyToken() {
      if (!token) {
        return;
      }

      const response = await userService.verifyRecoverPasswordToken(token);

      if (response.error) {
        setInvalidToken(true);
        return;
      }
    }

    verifyToken();
  }, []);

  if (token && invalidToken) {
    return (
      <div className="page center-content">
        <Card title="Link inválido">
          <p>O link de verificação é inválido.</p>
        </Card>
      </div>
    );
  }

  return token ? <ResetPasswordForm token={token} /> : <SendEmailForm />;
}

function SendEmailForm() {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [email, setEmail] = useState("");

  const sendEmail = async (event) => {
    event.preventDefault();

    setFormSubmitted(true);

    if (!formIsValid()) {
      return;
    }

    const result = await userService.recoverPassword({ email: email });

    if (!result) {
      return;
    }

    customSuccessToast("Email enviado com sucesso!");
  };

  function formIsValid() {
    if (!validateEmail(email).isValid) {
      return false;
    }

    return true;
  }

  return (
    <div className="page center-content">
      <Card title="Esqueceu sua senha?">
        <form onSubmit={sendEmail}>
          <Input
            label="Email"
            type="email"
            value={email}
            onChange={setEmail}
            validationError={
              formSubmitted &&
              !validateEmail(email).isValid &&
              validateEmail(email).errorMessage
            }
          />

          <Button
            onClick={sendEmail}
            className="margin-top-medium"
            size={ButtonSizes.large}
          >
            Enviar Email
          </Button>
        </form>
      </Card>
    </div>
  );
}

function ResetPasswordForm({ token }) {
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [succesfullyReset, setSuccesfullyReset] = useState(false);

  const resetPassword = async (event) => {
    event.preventDefault();
    setFormSubmitted(true);

    if (!formIsValid()) {
      return;
    }

    const payload = {
      password: password,
      token: token,
    };

    const data = await userService.resetPassword(payload);

    if (!data) {
      return;
    }

    setSuccesfullyReset(true);
  };

  function formIsValid() {
    if (!validatePassword(password).isValid) {
      return false;
    }
    if (password !== passwordConfirmation) {
      return false;
    }

    return true;
  }

  if (succesfullyReset) {
    return (
      <div className="page center-content">
        <Card title="Senha atualizada">
          <p>
            Sua senha foi atualizada com sucesso.<a href="/">Fazer login.</a>
          </p>
        </Card>
      </div>
    );
  }

  return (
    <div className="page center-content">
      <Card title="Esqueceu sua senha?">
        <form onSubmit={resetPassword}>
          <Input
            label="Nova Senha"
            type="password"
            value={password}
            onChange={setPassword}
            validationError={
              formSubmitted &&
              !validatePassword(password).isValid &&
              validatePassword(password).errorMessage
            }
            className="margin-top-medium"
          />
          <Input
            label="Confirme sua nova senha"
            type="password"
            value={passwordConfirmation}
            onChange={setPasswordConfirmation}
            validationError={
              formSubmitted &&
              password !== passwordConfirmation &&
              "As senhas não coincidem"
            }
            className="margin-top-medium"
          />

          <Button
            onClick={resetPassword}
            className="margin-top-medium"
            size={ButtonSizes.large}
          >
            Atualizar Senha
          </Button>
        </form>
      </Card>
    </div>
  );
}

export default ResetPasswordPage;

import React, { useContext, useState } from "react";
import "./UserMenu.scss";
import { UserAvatar, UserAvatarSizes } from "../UserAvatar/UserAvatar";
import userSession from "../../utils/userSession";
import { AppContext, navbarOptions } from "../../App";
import Modal from "../Modal/Modal";
import { validateEmail, validatePassword } from "../../utils/inputValidations";
import { Input, InputCheckbox, InputPhotoUploader } from "../Input/Input";
import Button from "../Button/Button";
import userService from "../../services/userService";
import Loader from "../Loader/Loader";

function UserMenu() {
  const currentUser = userSession.getCurrentUser();
  const [openMenu, setOpenMenu] = useState(false);
  const { changeNavbar } = useContext(AppContext);
  const [openUserInfo, setOpenUserInfo] = useState(false);

  if (!currentUser) {
    return null;
  }

  function closeUserInfoModal() {
    setOpenUserInfo(false);
  }

  const handleLogout = () => {
    userSession.removeSession();
    userSession.removeCurrentClient();
    setOpenMenu(false);
    closeUserInfoModal();
    changeNavbar(navbarOptions.none);
  };

  return (
    <div className="user-menu">
      <>
        <Modal
          isOpen={openUserInfo}
          onClose={closeUserInfoModal}
          title="Meu Perfil"
          subtitle="Editar minhas informações"
        >
          <UserInfoForm user={currentUser} handleLogout={handleLogout} />
        </Modal>

        <div onClick={() => setOpenMenu(true)}>
          <UserAvatar user={currentUser} size={UserAvatarSizes.large} />
        </div>
        {openMenu && (
          <>
            <div
              className="dropdown-overlay"
              onMouseDown={() => setOpenMenu(false)}
            ></div>
            <div
              className="user-dropdown-menu"
              onMouseDown={(e) => e.stopPropagation()}
            >
              <ul>
                <li
                  onClick={() => {
                    setOpenUserInfo(true);
                    setOpenMenu(false);
                  }}
                >
                  Meu Perfil
                </li>
                <li onClick={handleLogout}>Logout</li>
              </ul>
            </div>
          </>
        )}
      </>
    </div>
  );
}

function UserInfoForm({ user, handleLogout }) {
  const [name, setName] = useState(user.name);
  const [email, setEmail] = useState(user.email);
  const [previousPassword, setPreviousPassword] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [photo, setPhoto] = useState(user.photo);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSave = async (event) => {
    event.preventDefault();
    setFormSubmitted(true);

    if (!formIsValid()) {
      return;
    }

    const payload = {
      name: name,
      ...(photo instanceof Blob ? { photo } : {}),
    };

    if (changePassword) {
      payload.previousPassword = previousPassword;
      payload.password = password;
    }

    setLoading(true);

    const data = await userService.selfEdit(payload);

    setLoading(false);

    if (!data?.id) {
      return;
    }

    if (changePassword) {
      handleLogout();
    } else {
      userSession.setCurrentUser(data);
    }
  };

  function formIsValid() {
    if (!name) {
      return false;
    }
    if (!validateEmail(email).isValid) {
      return false;
    }

    if (changePassword && !previousPassword) {
      return false;
    }

    if (changePassword && !validatePassword(password).isValid) {
      return false;
    }
    if (changePassword && password !== passwordConfirmation) {
      return false;
    }

    return true;
  }

  return (
    <div className="user-form">
      {loading && <Loader />}
      <InputPhotoUploader value={photo} onChange={setPhoto} />
      <Input
        label="Nome"
        type="text"
        value={name}
        onChange={setName}
        validationError={formSubmitted && !name && "Nome é obrigatório"}
      />
      <Input
        label="Email"
        type="email"
        value={email}
        onChange={setEmail}
        validationError={
          formSubmitted &&
          !validateEmail(email).isValid &&
          validateEmail(email).errorMessage
        }
        className="margin-top-medium"
        disabled
      />

      <InputCheckbox
        label="Mudar senha"
        value={changePassword}
        onChange={setChangePassword}
        className="margin-top-large"
      />

      {changePassword && (
        <>
          <Input
            label="Senha Atual"
            type="password"
            value={previousPassword}
            onChange={setPreviousPassword}
            validationError={
              formSubmitted && !previousPassword && "Campo obrigatório"
            }
            className="margin-top-medium"
          />
          <Input
            label="Nova Senha"
            type="password"
            value={password}
            onChange={setPassword}
            validationError={
              formSubmitted &&
              !validatePassword(password).isValid &&
              validatePassword(password).errorMessage
            }
            className="margin-top-medium"
          />
          <Input
            label="Confirme sua nova senha"
            type="password"
            value={passwordConfirmation}
            onChange={setPasswordConfirmation}
            validationError={
              formSubmitted &&
              password !== passwordConfirmation &&
              "As senhas não coincidem"
            }
            className="margin-top-medium"
          />
        </>
      )}

      <Button onClick={handleSave} className="margin-top-medium">
        Salvar
      </Button>
    </div>
  );
}

export default UserMenu;

import React from "react";
import "./ItemDetails.scss";

function ItemDetails({ item, formReference, className = "" }) {
  const data = Object.keys(item).filter((key) => key !== "name");

  if (!item || !formReference) return null;

  function getLabelFromReference(reference) {
    const form = formReference.find((form) => form.value === reference);
    return form ? form.label : reference;
  }

  return (
    <div className={`item-view ${className}`}>
      <h3>{item.name?.value}</h3>
      {data.map((key, idx) => (
        <div key={idx} className="item-view-row margin-bottom-small">
          <label>{getLabelFromReference(item[key].label)}</label>
          <div>{item[key]?.value}</div>
        </div>
      ))}
    </div>
  );
}
export default ItemDetails;

import React, { useState, createContext } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import ClientsPage from "./pages/ClientsPage/ClientsPage";
import EmployeesPage from "./pages/EmployeesPage/EmployeesPage";
import LoginPage from "./pages/LoginPage/LoginPage";
import NotFoundPage from "./pages/NotFoundPage/NotFoundPage";
import AgencyNavbar from "./components/Navbars/AgencyNavbar";
import PrivateRoute from "./components/Routers/PrivateRoute";
import PublicRoute from "./components/Routers/PublicRoute";
import userSession from "./utils/userSession";
import AgencyDashboardPage from "./pages/AgencyDashboardPage/AgencyDashboardPage";
import ClientNavbar from "./components/Navbars/ClientNavbar";
import PrivateRouteFromClient from "./components/Routers/PrivateRouteFromClient";
import ClientTasksPage from "./pages/ClientTasksPage/ClientTasksPage";
import ClientBrandGuidePage from "./pages/ClientBrandGuidePage/ClientBrandGuidePage";
import "./css/app.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import SignupPage from "./pages/SignupPage/SignupPage";
import ClientComunicationPlanPage from "./pages/ClientComunicationPlanPage/ClientComunicationPlanPage";
import AgentsPage from "./pages/AgentsPage/AgentsPage";
import UserMenu from "./components/UserMenu/UserMenu";
import ResetPasswordPage from "./pages/ResetPasswordPage/ResetPasswordPage";
import TaskPage from "./pages/TaskPage/TaskPage";
import ClientSandboxPage from "./pages/ClientSandboxPage/ClientSandboxPage";
export const AppContext = createContext();

export const navbarOptions = {
  agency: "agency",
  client: "client",
  none: "none",
};

function chooseNavbar(option) {
  const isLoggedIn = userSession.isLoggedIn();
  const currentClient = userSession.getCurrentClient();

  if (!isLoggedIn) return navbarOptions.none;

  if (option === navbarOptions.client && !currentClient)
    return navbarOptions.agency;

  if (option === navbarOptions.agency && currentClient)
    return navbarOptions.client;

  return option;
}

function App() {
  const [navbarType, setNavbarType] = useState(
    chooseNavbar(navbarOptions.agency),
  );

  const changeNavbar = (navbarOption) => {
    const navbar = chooseNavbar(navbarOption);
    setNavbarType(navbar);
  };

  const contextValue = {
    changeNavbar,
  };

  return (
    <AppContext.Provider value={contextValue}>
      <ToastContainer />
      <Router>
        <div className="app">
          {navbarType == navbarOptions.agency && <AgencyNavbar />}
          {navbarType == navbarOptions.client && <ClientNavbar />}
          <UserMenu />

          <Routes>
            <Route path="*" element={<NotFoundPage />} />

            {/* Public routes */}

            <Route
              path="/login"
              element={<PublicRoute Component={LoginPage} />}
            />

            <Route
              path="/signup"
              element={<PublicRoute Component={SignupPage} />}
            />

            <Route
              path="/redefinir-senha"
              element={<PublicRoute Component={ResetPasswordPage} />}
            />

            {/* Agency Private routes */}

            <Route
              path="/"
              element={<PrivateRoute Component={ClientsPage} />}
            />
            <Route
              path="/funcionarios"
              element={<PrivateRoute Component={EmployeesPage} />}
            />
            <Route
              path="/agentes"
              element={<PrivateRoute Component={AgentsPage} />}
            />
            <Route
              path="/dashboard"
              element={<PrivateRoute Component={AgencyDashboardPage} />}
            />

            {/* Private routes from specific client */}

            <Route
              path="/cliente/:client_id/tarefas"
              element={<PrivateRouteFromClient Component={ClientTasksPage} />}
            />
            <Route
              path="/tarefa/:task_id"
              element={<PrivateRouteFromClient Component={TaskPage} />}
            />
            <Route
              path="/cliente/:client_id/plano-comunicacao"
              element={
                <PrivateRouteFromClient
                  Component={ClientComunicationPlanPage}
                />
              }
            />
            <Route
              path="/cliente/:client_id/manual-marca"
              element={
                <PrivateRouteFromClient Component={ClientBrandGuidePage} />
              }
            />
            <Route
              path="/cliente/:client_id/sandbox"
              element={<PrivateRouteFromClient Component={ClientSandboxPage} />}
            />
          </Routes>
        </div>
      </Router>
    </AppContext.Provider>
  );
}

export default App;

import React, { useEffect, useState } from "react";
import {
  Input,
  InputDropdownSingle,
  MultiSelectDropdown,
} from "../../components/Input/Input";
import {
  noProduct,
  noSection,
  noSpokesperson,
  noTargetAudience,
  taskOptions,
} from "../../utils/models/taskUtils";
import brandGuideService from "../../services/brandGuideService";
import userSession from "../../utils/userSession";
import Button, { ButtonTypes } from "../../components/Button/Button";
import "./TaskSimpleForm.scss";
import Loader from "../Loader/Loader";
import copyAgentService from "../../services/copyAgentService";

function TaskSimpleForm({ setText, setTaskDetails }) {
  const [taskArea, setTaskArea] = useState(null);
  const [taskGoal, setTaskGoal] = useState(null);
  const [taskChannel, setTaskChannel] = useState(null);
  const [taskDeliverable, setTaskDeliverable] = useState(null);
  const [textInstructions, setTextInstructions] = useState({});
  const [taskSection, setTaskSection] = useState(null);
  const [taskSpokesperson, setTaskSpokesperson] = useState(null);
  const [taskTargetAudiences, setTaskTargetAudience] = useState([]);
  const [taskProducts, setTaskProducts] = useState([]);

  const [availableSections, setSections] = useState([noSection]);
  const [availableSpokespeople, setSpokespeople] = useState([noSpokesperson]);
  const [availableTargetAudiences, setTargetAudiences] = useState([]);
  const [availableProducts, setProducts] = useState([]);

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [createdText, setCreatedText] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchSections() {
      const result = await brandGuideService.getBrandGuide({
        clientId: userSession.getCurrentClient().id,
        type: "sections",
      });

      const sectionsList = result?.content?.sections
        ? result.content.sections.concat([noSection])
        : [noSection];

      setSections(sectionsList);
    }

    async function fetchSpokespeople() {
      const result = await brandGuideService.getBrandGuide({
        clientId: userSession.getCurrentClient().id,
        type: "spokespeople",
      });

      const spokespeopleList = result?.content?.spokespeople
        ? result.content.spokespeople.concat([noSpokesperson])
        : [noSpokesperson];

      setSpokespeople(spokespeopleList);
    }

    async function fetchTargetAudiences() {
      const result = await brandGuideService.getBrandGuide({
        clientId: userSession.getCurrentClient().id,
        type: "targetAudiences",
      });

      setTargetAudiences(result?.content?.targetAudiences || []);
    }

    async function fetchProducts() {
      const result = await brandGuideService.getBrandGuide({
        clientId: userSession.getCurrentClient().id,
        type: "products",
      });

      setProducts(result?.content?.products || []);
    }

    fetchSections();
    fetchSpokespeople();
    fetchTargetAudiences();
    fetchProducts();
  }, []);

  const areaOptions = Object.values(taskOptions).map((area) => ({
    value: area.value,
    label: area.label,
  }));

  const goalOptions = Object.keys(taskOptions[taskArea]?.goals || []).map(
    (goal) => ({
      label: taskOptions[taskArea]?.goals[goal]?.text,
      value: goal,
    }),
  );

  const channelOptions = Object.keys(taskOptions[taskArea]?.channels || []).map(
    (channel) => ({
      label: taskOptions[taskArea]?.channels[channel]?.text,
      value: channel,
    }),
  );

  let deliverableOptions = [];

  if (taskArea === taskOptions.socialMedia.value && taskChannel) {
    deliverableOptions =
      taskOptions.socialMedia.channels[taskChannel]?.deliverables;
  } else if (taskArea && taskArea !== taskOptions.socialMedia.value) {
    deliverableOptions = taskOptions[taskArea]?.deliverables;
  }

  let textInstructionsOptions = [];

  if (taskDeliverable) {
    textInstructionsOptions = deliverableOptions.find(
      (option) => option.value === taskDeliverable,
    ).textInstructions;
  }

  function updateTextInstructions(value, addon) {
    setTextInstructions({
      ...textInstructions,
      [addon.value]: value,
    });
  }

  function selectArea(area) {
    setTaskArea(area);
    setTaskGoal("");
    setTaskChannel("");
    setTaskDeliverable("");
    setTextInstructions({});
  }

  function selectChannel(channel) {
    setTaskChannel(channel);
    setTaskDeliverable("");
    setTextInstructions({});
  }

  async function generateText() {
    setFormSubmitted(true);

    if (!taskArea || !taskGoal || !taskDeliverable) {
      return;
    }

    const selectedSection = availableSections.find(
      (section) => section.id === taskSection,
    );
    const selectedSpokesperson = availableSpokespeople.find(
      (spokesperson) => spokesperson.id === taskSpokesperson,
    );
    const selectedTargetAudiences = availableTargetAudiences.filter(
      (audience) => taskTargetAudiences.includes(audience.id),
    );
    const selectedProducts = availableProducts.filter((product) =>
      taskProducts.includes(product.id),
    );

    const taskDetails = {
      area: taskArea,
      goal: taskGoal,
      channel: taskChannel,
      deliverable: taskDeliverable,
      section: selectedSection,
      spokesperson: selectedSpokesperson,
      targetAudiences: selectedTargetAudiences,
      products: selectedProducts,
      textInstructions,
    };

    setLoading(true);

    const result = await copyAgentService.createText({
      details: taskDetails,
      clientId: userSession.getCurrentClient().id,
    });

    setLoading(false);

    if (!result?.answer) {
      return;
    }

    setTaskDetails(taskDetails);
    setText(result.answer);
    setCreatedText(true);
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="task-simple-form">
      <div className="title">
        <h3>Defina uma Tarefa</h3>
      </div>
      <div className="form-fields">
        <InputDropdownSingle
          value={taskArea}
          onChange={selectArea}
          options={areaOptions}
          placeholder="Selecione uma Área"
          validationError={formSubmitted && !taskArea && "Campo obrigatório"}
          className="small-label"
          label="Área de Publicação"
          disabled={createdText}
        />
        <InputDropdownSingle
          value={taskGoal}
          onChange={setTaskGoal}
          options={goalOptions}
          placeholder="Selecione um Objetivo"
          disabled={!taskArea || createdText}
          className="margin-top-small small-label"
          validationError={formSubmitted && !taskGoal && "Campo obrigatório"}
          label="Objetivo da Tarefa"
        />
        {channelOptions.length > 0 && (
          <InputDropdownSingle
            value={taskChannel}
            onChange={selectChannel}
            options={channelOptions}
            placeholder="Selecione um Canal"
            disabled={!taskArea || createdText}
            className="margin-top-small small-label"
            validationError={
              formSubmitted && !taskChannel && "Campo obrigatório"
            }
            label="Canal de Publicação"
          />
        )}
        {deliverableOptions.length > 0 && (
          <InputDropdownSingle
            value={taskDeliverable}
            onChange={setTaskDeliverable}
            options={deliverableOptions}
            placeholder="Selecione um Tipo de Peça"
            disabled={!taskArea || createdText}
            className="margin-top-small small-label"
            validationError={
              formSubmitted && !taskDeliverable && "Campo obrigatório"
            }
            label="Tipo de Peça"
          />
        )}
        <hr className="margin-top-medium margin-bottom-small" />
        <label>Informações Adicionais (campos opcionais):</label>
        <InputDropdownSingle
          value={taskSection}
          onChange={setTaskSection}
          options={availableSections.map((section) => {
            return { label: section.name.value, value: section.id };
          })}
          placeholder="Não aborda uma Editoria específica"
          className="margin-top-medium small-label"
          label="Editoria"
          disabled={createdText}
        />
        <InputDropdownSingle
          value={taskSpokesperson}
          onChange={setTaskSpokesperson}
          options={availableSpokespeople.map((spokesperson) => {
            return { label: spokesperson.name.value, value: spokesperson.id };
          })}
          placeholder="Não é escrito em nome de um Porta-Voz"
          className="margin-top-small small-label"
          label="Porta-Voz"
          disabled={createdText}
        />
        <MultiSelectDropdown
          value={taskTargetAudiences}
          onChange={setTaskTargetAudience}
          options={availableTargetAudiences.map((audience) => {
            return { label: audience.name.value, value: audience.id };
          })}
          placeholder="Não há um Público Alvo específico"
          className="margin-top-small small-label"
          emptyOption={noTargetAudience.name.value}
          label="Público Alvo"
          disabled={createdText}
        />
        <MultiSelectDropdown
          value={taskProducts}
          onChange={setTaskProducts}
          options={availableProducts.map((product) => {
            return { label: product.name.value, value: product.id };
          })}
          placeholder="Não cita um produto ou serviço específico"
          className="margin-top-small small-label"
          emptyOption={noProduct.name.value}
          label="Produtos ou Serviços mencionados"
          disabled={createdText}
        />

        {textInstructionsOptions.map((addon, idx) => (
          <div className="addon margin-top-small small-label" key={idx}>
            <Input
              placeholder={addon.placeholder}
              onChange={(value) => updateTextInstructions(value, addon)}
              value={textInstructions?.[addon.value]}
              type="textarea"
              label={addon.text}
              disabled={createdText}
            />
          </div>
        ))}
      </div>
      <div className="submit-button margin-top-medium">
        {createdText ? (
          <Button
            type={ButtonTypes.secondary}
            onClick={() => {
              setText("");
              setCreatedText(false);
              setFormSubmitted(false);
            }}
          >
            Começar Denovo
          </Button>
        ) : (
          <Button className="button-primary" onClick={generateText}>
            Gerar Texto
          </Button>
        )}
      </div>
    </div>
  );
}

export default TaskSimpleForm;

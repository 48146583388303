import {
  COPY_AGENT_BASE_URL,
  apiDefaultErrorHandler,
  httpServiceCreate,
  payloadTypes,
} from "../utils/serviceUtils";

const taskHelper = async (payload) => {
  try {
    const url = new URL(`${COPY_AGENT_BASE_URL}/task_helper`);
    const data = await httpServiceCreate(
      url,
      payload,
      payloadTypes.JSON,
      false,
    );
    return data;
  } catch (error) {
    return null;
  }
};

const insertText = async (payload) => {
  try {
    const url = new URL(`${COPY_AGENT_BASE_URL}/insert_text`);
    const data = await httpServiceCreate(
      url,
      payload,
      payloadTypes.JSON,
      false,
    );
    return data;
  } catch (error) {
    return null;
  }
};

const modifyText = async (payload) => {
  try {
    const url = new URL(`${COPY_AGENT_BASE_URL}/modify_text`);
    const data = await httpServiceCreate(
      url,
      payload,
      payloadTypes.JSON,
      false,
    );
    return data;
  } catch (error) {
    return null;
  }
};

const generateContent = async (payload) => {
  try {
    const url = new URL(`${COPY_AGENT_BASE_URL}/generate_content`);
    const data = await httpServiceCreate(
      url,
      payload,
      payloadTypes.JSON,
      false,
    );
    return data;
  } catch (error) {
    return null;
  }
};

const brandGuideReview = async (payload) => {
  try {
    const url = new URL(`${COPY_AGENT_BASE_URL}/brandguide_review`);
    const data = await httpServiceCreate(
      url,
      payload,
      payloadTypes.JSON,
      false,
    );
    return data;
  } catch (error) {
    return null;
  }
};

const createText = async (payload) => {
  try {
    const url = new URL(`${COPY_AGENT_BASE_URL}/create_text`);
    const data = await httpServiceCreate(url, payload);
    return data;
  } catch (error) {
    apiDefaultErrorHandler(error);
    return null;
  }
};

const evaluateCreateText = async (payload) => {
  try {
    const url = new URL(`${COPY_AGENT_BASE_URL}/create_text/evaluate`);
    const data = await httpServiceCreate(url, payload);
    return data;
  } catch (error) {
    apiDefaultErrorHandler(error);
    return null;
  }
};

const copyAgentService = {
  taskHelper,
  insertText,
  modifyText,
  generateContent,
  createText,
  evaluateCreateText,
  brandGuideReview,
};

export default copyAgentService;

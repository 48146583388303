import React from "react";
import "./ClientsPage.scss";
import PageTitle from "../../components/PageTitle/PageTitle";
import { CrudArea, crudModels } from "../../components/CrudArea/CrudArea";

function ClientsPage() {
  return (
    <div className="page">
      <PageTitle
        title="Clientes"
        description="Gerencie os clientes que sua equipe trabalha"
      />

      <CrudArea model={crudModels.client} />
    </div>
  );
}

export default ClientsPage;

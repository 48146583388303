import React, { useEffect, useState } from "react";
import "./CreateOrUpdateTaskModal.scss";
import Modal, { ModalSizes } from "../Modal/Modal";
import Button from "../Button/Button";
import { Input, InputCheckbox, InputDatePicker } from "../Input/Input";
import { XMarkIcon } from "@heroicons/react/24/solid";
import userSession from "../../utils/userSession";
import taskService from "../../services/taskService";
import {
  getDeliverableOptions,
  noProduct,
  noSection,
  noSpokesperson,
  noTargetAudience,
  taskOptions,
} from "../../utils/models/taskUtils";
import TaskPreview from "../TaskPreview/TaskPreview";
import {
  convertDateToEpoch,
  getDateFromEpoch,
} from "../../utils/javascriptUtils";
import brandGuideService from "../../services/brandGuideService";
import Loader from "../Loader/Loader";
import ItemDetails from "../ItemDetails/ItemDetails";
import Pill from "../Pill/Pill";
import { brandGuide } from "../../utils/models/brandGuideUtils";

function CreateOrUpdateTaskModal({
  isOpen,
  onClose,
  task = undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  reloadData = () => {},
}) {
  const emptyDataState = { details: { textInstructions: {} } };
  const [data, setData] = useState(task?.id ? task : emptyDataState);
  const [validationError, setValidationError] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const editMode = !!task;

  useEffect(() => {
    setData(task?.id ? task : emptyDataState);
  }, [task]);

  const tabs = {
    AreaForm: AreaForm,
    GoalForm: GoalForm,
    DeliverableForm: DeliverableForm,
    SectionForm: SectionForm,
    TargetAudiencesForm: TargetAudiencesForm,
    ProductsForm: ProductsForm,
    SpokespersonForm: SpokespersonForm,
    DateForm: DateForm,
    TagsForm: TagsForm,
  };

  const tabsLength = Object.keys(tabs).length;
  const TabToRender = Object.values(tabs)[activeTab];

  function validateActiveTab() {
    if (TabToRender === tabs.AreaForm && !data.details?.area) {
      setValidationError("Selecione uma área antes de continuar");
      return false;
    }

    if (TabToRender === tabs.GoalForm && !data.details?.goal) {
      setValidationError("Selecione um objetivo antes de continuar");
      return false;
    }

    if (
      TabToRender === tabs.SectionForm &&
      !data.details?.section &&
      !editMode
    ) {
      setValidationError("Selecione uma opção para continuar");
      return false;
    }

    if (
      TabToRender === tabs.SpokespersonForm &&
      !data.details?.spokesperson &&
      !editMode
    ) {
      setValidationError("Selecione uma opção para continuar");
      return false;
    }

    if (
      TabToRender === tabs.TargetAudiencesForm &&
      !data.details?.targetAudiences
    ) {
      setValidationError("Selecione uma opção para continuar");
      return false;
    }

    if (TabToRender === tabs.ProductsForm && !data.details?.products) {
      setValidationError("Selecione uma opção para continuar");
      return false;
    }

    if (TabToRender === tabs.DeliverableForm && !data.details?.deliverable) {
      setValidationError("Selecione um tipo de peça antes de continuar");
      return false;
    }

    if (TabToRender === tabs.DateForm && !data.dueDate) {
      setValidationError("Selecione uma data antes de continuar");
      return false;
    }

    setValidationError("");
    return true;
  }

  async function saveTask() {
    let result;
    const payload = {
      ...data,
      details: {
        ...data.details,
        section: data.details?.section?.id,
        spokesperson: data.details?.spokesperson?.id,
        targetAudiences: data.details?.targetAudiences?.map(
          (audience) => audience.id,
        ),
        products: data.details?.products?.map((product) => product.id),
      },
      clientId: userSession.getCurrentClient().id,
    };

    setLoading(true);

    if (editMode) {
      result = await taskService.updateTask(task.id, payload);
    } else {
      result = await taskService.createTask(payload);
    }

    setLoading(false);

    if (!result?.id) {
      return;
    }

    setData(emptyDataState);
    reloadData();
    onClose();
  }

  const handlePrevious = () => {
    setValidationError("");

    if (activeTab > 0) {
      setActiveTab(activeTab - 1);
    }
  };

  const handleNext = () => {
    if (!validateActiveTab()) {
      return;
    }

    if (activeTab < tabsLength - 1) {
      setActiveTab(activeTab + 1);
    }

    if (activeTab === tabsLength - 1) {
      saveTask();
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={editMode ? "Editar Tarefa" : "Criar Tarefa"}
      size={ModalSizes.xlarge}
    >
      <div className="create-tasks-modal margin-top-medium">
        <div className="form-and-preview">
          <div className="form-content">
            <TabToRender data={data} setData={setData} />
          </div>
          <div className="form-preview">
            <TaskPreview task={data} className="blue-container" />
          </div>
        </div>

        <div className="form-footer margin-top-large">
          {validationError && (
            <div className="validation-error">{validationError}</div>
          )}

          <div className={`form-buttons ${activeTab === 0 ? "flex-end" : ""}`}>
            {activeTab !== 0 && (
              <Button onClick={handlePrevious}>Anterior</Button>
            )}
            {activeTab !== tabsLength - 1 && (
              <Button onClick={handleNext}>Próximo</Button>
            )}
            {activeTab === tabsLength - 1 && (
              <Button onClick={handleNext}>Finalizar</Button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
}

function AreaForm({ data, setData }) {
  function selectArea(input) {
    setData({ details: { area: input, textInstructions: {} } });
  }

  return (
    <div className="area-form">
      <h3>Selecione a Área</h3>
      <div className="task-input-group">
        {Object.values(taskOptions).map((option) => (
          <div
            key={option.value}
            className={`area-input ${data.details?.area === option.value ? "selected" : ""}`}
            onClick={() => selectArea(option.value)}
          >
            {option.text}
          </div>
        ))}
      </div>
    </div>
  );
}

function GoalForm({ data, setData }) {
  function selectGoal(input) {
    setData({ ...data, details: { ...data.details, goal: input } });
  }

  return (
    <div className="goal-form">
      <h3>Selecione o Objetivo</h3>
      <div className="task-input-group">
        {Object.values(taskOptions[data.details?.area]?.goals).map((option) => (
          <div
            key={option.value}
            className={`goal-input margin-bottom-small ${data.details?.goal === option.value ? "selected" : ""}`}
            onClick={() => selectGoal(option.value)}
          >
            {option.text}
          </div>
        ))}
      </div>
    </div>
  );
}

function DeliverableForm({ data, setData }) {
  const channels =
    Object.values(taskOptions[data.details?.area]?.channels) || [];
  const deliverableOptions = getDeliverableOptions(data);

  function selectChannel(input) {
    const newData = { ...data };
    newData.details.channel = input;
    delete newData.details.deliverable;
    newData.details.textInstructions = {};

    setData(newData);
  }

  function selectDeliverable(input) {
    const newData = { ...data };
    newData.details.deliverable = input;
    newData.details.textInstructions = {};

    setData(newData);
  }

  function updateAddOn(value, addon) {
    setData({
      ...data,
      details: {
        ...data.details,
        textInstructions: {
          ...data.details?.textInstructions,
          [addon.value]: value,
        },
      },
    });
  }

  return (
    <div className="deliverable-form">
      {channels.length > 0 && (
        <>
          <h3>Selecione o canal</h3>
          <div className="task-input-group">
            {channels.map((option, idx) => (
              <div className="option-checkbox" key={idx}>
                <InputCheckbox
                  value={data.details?.channel === option.value}
                  onChange={() => selectChannel(option.value)}
                />
                <label className="checkbox-container">{option.text}</label>
              </div>
            ))}
          </div>
        </>
      )}
      <div className="task-input-group">
        {deliverableOptions && (
          <div className="deliverables-list">
            <h3>Selecione o tipo de peça</h3>
            <div className="task-input-group">
              {deliverableOptions.map((option, idx) => (
                <div className="option-checkbox" key={idx}>
                  <InputCheckbox
                    value={data.details?.deliverable === option.value}
                    onChange={() => selectDeliverable(option.value)}
                  />
                  <label className="checkbox-container">{option.text}</label>
                </div>
              ))}
            </div>
          </div>
        )}

        {data.details?.deliverable && (
          <div className="task-input-group">
            <h3>Quer incluir algum desses itens?</h3>
            {deliverableOptions
              .find((option) => option.value === data.details?.deliverable)
              .textInstructions?.map((addon, idx) => (
                <div className="addon margin-bottom-small" key={idx}>
                  <label className="margin-bottom-small">{addon.text}</label>
                  <Input
                    placeholder={addon.placeholder}
                    onChange={(value) => updateAddOn(value, addon)}
                    value={data.details?.textInstructions?.[addon.value]}
                    type="textarea"
                  />
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
}

function SectionForm({ data, setData }) {
  const [sections, setSections] = useState([]);
  const [loading, setLoading] = useState(false);
  const selectedSection = data.details?.section;

  function selectSection(input) {
    setData({ ...data, details: { ...data.details, section: input } });
  }

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const result = await brandGuideService.getBrandGuide({
        clientId: userSession.getCurrentClient().id,
        type: "sections",
      });
      setLoading(false);

      const sectionsList = result?.content?.sections
        ? result.content.sections.concat([noSection])
        : [noSection];

      setSections(sectionsList);

      if (sectionsList.length === 1) {
        selectSection(noSection);
      }
    }
    fetchData();
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="details-form">
      <h3>Selecione uma editoria para ser abordada no texto</h3>

      <div className="task-input-group">
        {sections.map((section) => (
          <div
            key={section.id}
            className={`details-element-select margin-bottom-small ${selectedSection?.id === section.id || (!selectedSection && section.id === noSection.id) ? "selected" : ""}`}
            onClick={() => selectSection(section)}
          >
            {section.name.value}
          </div>
        ))}
      </div>
      {selectedSection && selectedSection?.id != noSection.id && (
        <div className="margin-top-medium">
          <h4>Detalhes da editoria:</h4>
          <div className="selected-items">
            <ItemDetails
              item={selectedSection}
              formReference={brandGuide.sections.form}
              className="margin-top-medium"
            />
          </div>
        </div>
      )}

      <div className="margin-top-large">
        {sections.length > 1 ? (
          <p className="font-size-small">
            Quer falar sobre outro assunto? Que tal{" "}
            <a
              href={`/cliente/${userSession.getCurrentClient().id}/manual-marca?tab=sections`}
            >
              cadastrar uma nova editoria
            </a>
            ?
          </p>
        ) : (
          <p>
            Não há editorias cadastradas para esse cliente. Gostaria de{" "}
            <a
              href={`/cliente/${userSession.getCurrentClient().id}/manual-marca?tab=sections`}
            >
              cadastrar uma editoria
            </a>
            ?
          </p>
        )}
      </div>
    </div>
  );
}

function SpokespersonForm({ data, setData }) {
  const [spokespeople, setSpokespeople] = useState([]);
  const [loading, setLoading] = useState(false);
  const selectedSpokesperson = data.details?.spokesperson;

  function selectSpokesperson(input) {
    setData({ ...data, details: { ...data.details, spokesperson: input } });
  }

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const result = await brandGuideService.getBrandGuide({
        clientId: userSession.getCurrentClient().id,
        type: "spokespeople",
      });
      setLoading(false);

      const spokespeopleList = result?.content?.spokespeople
        ? result.content.spokespeople.concat([noSpokesperson])
        : [noSpokesperson];

      setSpokespeople(spokespeopleList);

      if (spokespeopleList.length === 1) {
        selectSpokesperson(noSpokesperson);
      }
    }
    fetchData();
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="details-form">
      <h3>Selecione um porta voz que escreve esse texto (se houver)</h3>

      <div className="task-input-group">
        {spokespeople.map((spokesperson) => (
          <div
            key={spokesperson.id}
            className={`details-element-select margin-bottom-small ${selectedSpokesperson?.id === spokesperson.id || (!selectedSpokesperson && spokesperson.id === noSpokesperson.id) ? "selected" : ""}`}
            onClick={() => selectSpokesperson(spokesperson)}
          >
            {spokesperson.name.value}
          </div>
        ))}
      </div>
      {selectedSpokesperson &&
        selectedSpokesperson?.id != noSpokesperson.id && (
          <div className="margin-top-medium">
            <h4>Detalhes do Porta Voz:</h4>
            <div className="selected-items">
              <ItemDetails
                item={selectedSpokesperson}
                formReference={brandGuide.spokespeople.form}
                className="margin-top-medium"
              />
            </div>
          </div>
        )}

      <div className="margin-top-large">
        {spokespeople.length > 1 ? (
          <p className="font-size-small">
            Não encontrou o porta voz correto? Que tal{" "}
            <a
              href={`/cliente/${userSession.getCurrentClient().id}/manual-marca?tab=spokespeole`}
            >
              cadastrar um novo porta voz
            </a>
            ?
          </p>
        ) : (
          <p>
            Não há porta vozes cadastrados para esse cliente. Gostaria de{" "}
            <a
              href={`/cliente/${userSession.getCurrentClient().id}/manual-marca?tab=spokespeole`}
            >
              cadastrar um novo porta voz
            </a>
            ?
          </p>
        )}
      </div>
    </div>
  );
}

function TargetAudiencesForm({ data, setData }) {
  const [targetAudiences, setTargetAudiences] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedNoTargetAudience, setSelectedNoTargetAudience] =
    useState(false);
  const selectedTargetAudiences = data.details?.targetAudiences;

  function selectTargetAudience(input) {
    if (input.id === noTargetAudience.id) {
      setData({
        ...data,
        details: { ...data.details, targetAudiences: [input] },
      });
      setSelectedNoTargetAudience(true);
      return;
    }

    setSelectedNoTargetAudience(false);

    if (!selectedTargetAudiences) {
      setData({
        ...data,
        details: { ...data.details, targetAudiences: [input] },
      });
      return;
    }

    const isSelected = selectedTargetAudiences?.some(
      (audienceItem) => audienceItem.id === input.id,
    );
    let updatedTargetAudiences;
    if (isSelected) {
      updatedTargetAudiences = selectedTargetAudiences?.filter(
        (audience) => audience.id !== input.id,
      );
    } else {
      updatedTargetAudiences = [...selectedTargetAudiences, input];
    }

    updatedTargetAudiences = updatedTargetAudiences.filter(
      (audience) => audience.id !== noTargetAudience.id,
    );

    setData((prevData) => ({
      ...prevData,
      details: {
        ...prevData.details,
        targetAudiences: updatedTargetAudiences,
      },
    }));
  }

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const result = await brandGuideService.getBrandGuide({
        clientId: userSession.getCurrentClient().id,
        type: "targetAudiences",
      });
      setLoading(false);

      const audiencesList = result?.content?.targetAudiences
        ? result.content.targetAudiences.concat([noTargetAudience])
        : [noTargetAudience];

      setTargetAudiences(audiencesList);

      if (audiencesList.length === 1) {
        selectTargetAudience(noTargetAudience);
      }
    }
    fetchData();
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="details-form">
      <h3>Selecione os públicos alvo dessa peça</h3>

      <div className="task-input-group">
        {targetAudiences.map((audience) => (
          <div
            key={audience.id}
            className={`details-element-select margin-bottom-small ${
              selectedTargetAudiences?.some(
                (audienceItem) => audienceItem.id === audience.id,
              )
                ? "selected"
                : ""
            }`}
            onClick={() => selectTargetAudience(audience)}
          >
            {audience.name.value}
          </div>
        ))}
      </div>
      {selectedTargetAudiences?.length > 0 && !selectedNoTargetAudience && (
        <div className="margin-top-medium">
          <h4>Detalhes dos Públicos Alvo Selecionados:</h4>
          <div className="selected-items">
            {selectedTargetAudiences.map((audience) => (
              <ItemDetails
                key={audience.id}
                item={audience}
                formReference={brandGuide.targetAudiences.form}
                className="margin-top-small"
              />
            ))}
          </div>
        </div>
      )}

      <div className="margin-top-large">
        {targetAudiences.length > 1 ? (
          <p className="font-size-small">
            Não encontrou o público alvo correto? Que tal{" "}
            <a
              href={`/cliente/${userSession.getCurrentClient().id}/manual-marca?tab=targetAudiences`}
            >
              cadastrar um novo público alvo
            </a>
            ?
          </p>
        ) : (
          <p>
            Não há públicos alvo cadastrados para esse cliente. Gostaria de{" "}
            <a
              href={`/cliente/${userSession.getCurrentClient().id}/manual-marca?tab=targetAudiences`}
            >
              cadastrar um novo público alvo
            </a>
            ?
          </p>
        )}
      </div>
    </div>
  );
}

function ProductsForm({ data, setData }) {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedNoProduct, setSelectedNoProduct] = useState(false);
  const selectedProducts = data.details?.products;

  function selectProduct(input) {
    if (input.id === noProduct.id) {
      setData({
        ...data,
        details: { ...data.details, products: [input] },
      });
      setSelectedNoProduct(true);
      return;
    }

    setSelectedNoProduct(false);

    if (!selectedProducts) {
      setData({
        ...data,
        details: { ...data.details, products: [input] },
      });
      return;
    }

    const isSelected = selectedProducts?.some(
      (productItem) => productItem.id === input.id,
    );

    let updatedProducts;

    if (isSelected) {
      updatedProducts = selectedProducts?.filter(
        (product) => product.id !== input.id,
      );
    } else {
      updatedProducts = [...data.details.products, input];
    }

    updatedProducts = updatedProducts.filter(
      (product) => product.id !== noProduct.id,
    );

    setData((prevData) => ({
      ...prevData,
      details: {
        ...prevData.details,
        products: updatedProducts,
      },
    }));
  }

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const result = await brandGuideService.getBrandGuide({
        clientId: userSession.getCurrentClient().id,
        type: "products",
      });
      setLoading(false);

      const productsList = result?.content?.products
        ? result.content.products.concat([noProduct])
        : [noProduct];

      setProducts(productsList);

      if (productsList.length === 1) {
        selectProduct(noProduct);
      }
    }
    fetchData();
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="details-form">
      <h3>Selecione produços ou serviços que devem ser mencionados na peça</h3>

      <div className="task-input-group">
        {products.map((product) => (
          <div
            key={product.id}
            className={`details-element-select margin-bottom-small ${
              selectedProducts?.some(
                (productItem) => productItem.id === product.id,
              )
                ? "selected"
                : ""
            }`}
            onClick={() => selectProduct(product)}
          >
            {product.name?.value}
          </div>
        ))}
      </div>
      {selectedProducts?.length > 0 && !selectedNoProduct && (
        <div className="margin-top-medium">
          <h4>Detalhes dos Produtos Selecionados:</h4>
          <div className="selected-items">
            {selectedProducts.map((product) => (
              <ItemDetails
                key={product.id}
                item={product}
                formReference={brandGuide.products.form}
                className="margin-top-small"
              />
            ))}
          </div>
        </div>
      )}

      <div className="margin-top-large">
        {products.length > 1 ? (
          <p className="font-size-small">
            Não encontrou o produto ou serviço da sua marca? Que tal{" "}
            <a
              href={`/cliente/${userSession.getCurrentClient().id}/manual-marca?tab=products`}
            >
              cadastrar um novo produto ou serviço
            </a>
            ?
          </p>
        ) : (
          <p>
            Não há produtos ou serviços cadastrados para esse cliente. Gostaria
            de{" "}
            <a
              href={`/cliente/${userSession.getCurrentClient().id}/manual-marca?tab=products`}
            >
              cadastrar um novo produto ou serviço
            </a>
            ?
          </p>
        )}
      </div>
    </div>
  );
}

/*
function DetailsForm({ data, setData }) {
  function selectGeneralPrompt(input) {
    setData({ ...data, details: { ...data.details, generalPrompt: input } });
  }

  return (
    <div className="details-form">
      <h3>
        Se quiser, pode falar em linhas gerais o que você espera dessa tarefa
      </h3>
      <Input
        placeholder="Quero que seja feito..."
        onChange={selectGeneralPrompt}
        value={data.details?.generalPrompt}
        type="textarea"
      />
    </div>
  );
}
*/

function DateForm({ data, setData }) {
  function handleDateChange(date) {
    const epochDate = convertDateToEpoch(date);
    setData({ ...data, dueDate: epochDate });
  }

  return (
    <div className="date-form">
      <h3>Informe a data que essa peça deve ser publicada</h3>
      <InputDatePicker
        value={getDateFromEpoch(data.dueDate)}
        onChange={handleDateChange}
        locale="ptBR"
        className="date-picker"
      />
    </div>
  );
}

function TagsForm({ data, setData }) {
  const [tag, setTag] = useState("");

  function handleTagChange(value) {
    setTag(value);
  }

  function handleTagSubmit(event) {
    event.preventDefault();
    const tags = data.tags || [];
    if (tag.trim() !== "") {
      setData({
        ...data,
        tags: [...tags, tag.trim()],
      });
      setTag("");
    }
  }

  function handleTagRemove(index) {
    const tags = data.tags || [];
    const updatedTags = [...tags];
    updatedTags.splice(index, 1);
    setData({
      ...data,
      tags: updatedTags,
    });
  }

  return (
    <div className="tags-form">
      <div className="tags-form">
        <h3>
          Adicione marcadores a esta tarefa para filtra-la depois em seus
          relatórios (opcional)
        </h3>
        {data.tags?.map((tag, index) => (
          <div key={index} className="tag-item margin-bottom-small">
            <Pill value={tag} className="margin-right-small" />
            <XMarkIcon
              onClick={() => handleTagRemove(index)}
              className="remove-tag-icon margin-left-small"
            />
          </div>
        ))}
      </div>
      <form
        onSubmit={handleTagSubmit}
        className="new-tag-input margin-top-medium"
      >
        <Input
          type="text"
          placeholder="Digite um marcador"
          value={tag}
          onChange={handleTagChange}
          className="margin-right-small"
        />
        <Button type="submit">Adicionar</Button>
      </form>
    </div>
  );
}

export default CreateOrUpdateTaskModal;

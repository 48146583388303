import React, { useEffect, useState } from "react";

import "./RateAgentComponent.scss";
import { Input } from "../Input/Input";
import {
  HandThumbDownIcon as NotSelectedThumbDownIcon,
  HandThumbUpIcon as NotSelectedThumbUpIcon,
  StarIcon as NotSelectedStarIcon,
} from "@heroicons/react/24/outline";
import {
  HandThumbDownIcon as SelectedThumbDownIcon,
  HandThumbUpIcon as SelectedThumbUpIcon,
  StarIcon as SelectedStarIcon,
  CheckCircleIcon,
} from "@heroicons/react/24/solid";
import Button from "../Button/Button";
import userSession from "../../utils/userSession";
import copyAgentService from "../../services/copyAgentService";
import Loader from "../Loader/Loader";

export const ClientEnvs = {
  sandbox: "sandbox",
  production: "production",
};

function RateAgentComponent({ clientEnv, taskDetails, evaluatedText }) {
  const [goalFit, setGoalFit] = useState(null);
  const [channelFit, setChannelFit] = useState(null);
  const [sectionFit, setSectionFit] = useState(null);
  const [audienceFit, setAudienceFit] = useState(null);
  const [productFit, setProductFit] = useState(null);
  const [spokespersonFit, setSpokespersonFit] = useState(null);
  const [brandguideFit, setBrandguideFit] = useState(null);
  const [rating, setRating] = useState(null);
  const [text, setText] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [finishedEvaluation, setFinishedEvaluation] = useState(false);
  const [loading, setLoading] = useState(false);

  async function saveEvaluation() {
    setFormSubmitted(true);

    if (
      goalFit === null ||
      channelFit === null ||
      sectionFit === null ||
      audienceFit === null ||
      productFit === null ||
      spokespersonFit === null ||
      brandguideFit === null ||
      !rating ||
      !text
    ) {
      return;
    }

    const payload = {
      goalFit: goalFit,
      channelFit: channelFit,
      sectionFit: sectionFit,
      audienceFit: audienceFit,
      productFit: productFit,
      spokespersonFit: spokespersonFit,
      brandguideFit: brandguideFit,
      rating: rating,
      text: text,
      clientId: userSession.getCurrentClient().id,
      clientEnv: clientEnv,
      details: taskDetails,
      evaluatedText: evaluatedText,
    };

    setLoading(true);

    const result = await copyAgentService.evaluateCreateText(payload);

    setLoading(false);

    if (!result) {
      return;
    }

    setFinishedEvaluation(true);
  }

  useEffect(() => {
    setGoalFit(null);
    setChannelFit(null);
    setSectionFit(null);
    setAudienceFit(null);
    setProductFit(null);
    setSpokespersonFit(null);
    setBrandguideFit(null);
    setRating(null);
    setText(null);
    setFormSubmitted(false);
    setFinishedEvaluation(false);
  }, [evaluatedText]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="rate-agent-component">
      <div className="page-title">
        <h1>Avalie o Conteúdo Desenvolvido</h1>
      </div>
      <div className="rate-section margin-top-small">
        <div className="rate-section-title">
          <h2>
            Considerando a assertividade, coerência e contextualização esperada:
          </h2>
        </div>
        <div className="rate-section-content margin-top-small">
          <TopicRating
            topic="Cumpriu com o objetivo?"
            value={goalFit}
            setValue={setGoalFit}
            finishedEvaluation={finishedEvaluation}
          />
          {formSubmitted && goalFit === null && (
            <div className="error">Por favor, responda esse campo.</div>
          )}
          <TopicRating
            topic="É coerente com o canal?"
            className="margin-top-small"
            value={channelFit}
            setValue={setChannelFit}
            finishedEvaluation={finishedEvaluation}
          />
          {formSubmitted && channelFit === null && (
            <div className="error">Por favor, responda esse campo.</div>
          )}
          <TopicRating
            topic="Respeitou a editoria?"
            className="margin-top-small"
            value={sectionFit}
            setValue={setSectionFit}
            finishedEvaluation={finishedEvaluation}
          />
          {formSubmitted && sectionFit === null && (
            <div className="error">Por favor, responda esse campo.</div>
          )}
          <TopicRating
            topic="Fala com o público-alvo?"
            className="margin-top-small"
            value={audienceFit}
            setValue={setAudienceFit}
            finishedEvaluation={finishedEvaluation}
          />
          {formSubmitted && audienceFit === null && (
            <div className="error">Por favor, responda esse campo.</div>
          )}
          <TopicRating
            topic="Menciona corretamente produtos ou serviços?"
            className="margin-top-small"
            value={productFit}
            setValue={setProductFit}
            finishedEvaluation={finishedEvaluation}
          />
          {formSubmitted && productFit === null && (
            <div className="error">Por favor, responda esse campo.</div>
          )}
          <TopicRating
            topic="Fala corretamente em nome do porta-voz? "
            className="margin-top-small"
            value={spokespersonFit}
            setValue={setSpokespersonFit}
            finishedEvaluation={finishedEvaluation}
          />
          {formSubmitted && spokespersonFit === null && (
            <div className="error">Por favor, responda esse campo.</div>
          )}
          <TopicRating
            topic="Respeita as diretrizes do manual de marca?"
            className="margin-top-small"
            value={brandguideFit}
            setValue={setBrandguideFit}
            finishedEvaluation={finishedEvaluation}
          />
          {formSubmitted && brandguideFit === null && (
            <div className="error">Por favor, responda esse campo.</div>
          )}
        </div>
      </div>
      <div className="rate-section margin-top-medium">
        <div className="rate-section-title">
          <h2>Você usaria esse conteúdo?</h2>
        </div>
        <div className="rate-section-content margin-top-small">
          <FiveStarRating
            rating={rating}
            setRating={setRating}
            finishedEvaluation={finishedEvaluation}
          />
        </div>
        {formSubmitted && !rating && (
          <div className="error center">Por favor, responda esse campo.</div>
        )}
      </div>
      <div className="rate-section margin-top-medium">
        <div className="rate-section-title">
          <h2>Por favor, explique a sua avaliação</h2>
        </div>
        <div className="rate-section-content margin-top-small">
          <Input
            type="textarea"
            value={text}
            onChange={setText}
            disabled={finishedEvaluation}
          />
        </div>
        {formSubmitted && !text && (
          <div className="error center">Por favor, responda esse campo.</div>
        )}
      </div>
      <div className="footer margin-top-medium">
        {finishedEvaluation ? (
          <div className="thanks-message">
            Recebemos sua avaliação. Muito obrigado!
            <CheckCircleIcon className="icon margin-left-small" />
          </div>
        ) : (
          <Button onClick={saveEvaluation}>Enviar Avaliação</Button>
        )}
      </div>
    </div>
  );
}

function TopicRating({
  topic,
  value,
  setValue,
  className,
  finishedEvaluation,
}) {
  function rateTopic(rating) {
    if (finishedEvaluation) {
      return;
    }
    setValue(rating);
  }

  return (
    <div className={`topic-rating ${className}`}>
      <div className="topic-rating-label">
        <label>{topic}</label>
      </div>
      <div className="topic-rating-content margin-left-medium">
        {value === true ? (
          <SelectedThumbUpIcon
            className="icon"
            onClick={() => rateTopic(true)}
          />
        ) : (
          <NotSelectedThumbUpIcon
            className="icon"
            onClick={() => rateTopic(true)}
          />
        )}
        {value === false ? (
          <SelectedThumbDownIcon
            className="icon margin-left-small"
            onClick={() => rateTopic(false)}
          />
        ) : (
          <NotSelectedThumbDownIcon
            className="icon margin-left-small"
            onClick={() => rateTopic(false)}
          />
        )}
        <div
          className={`na-icon margin-left-small ${value === "" ? "selected" : ""}`}
          onClick={() => rateTopic("")}
        >
          N/A
        </div>
      </div>
    </div>
  );
}

function FiveStarRating({ rating, setRating, finishedEvaluation }) {
  function rateText(rating) {
    if (finishedEvaluation) {
      return;
    }

    setRating(rating);
  }

  return (
    <div className="five-star-rating">
      <div className="star" onClick={() => rateText(1)}>
        {rating >= 1 ? (
          <SelectedStarIcon className="icon" />
        ) : (
          <NotSelectedStarIcon className="icon" />
        )}
        <label className="margin-top-small">Não usaria. Errou feio.</label>
      </div>
      <div className="star" onClick={() => rateText(2)}>
        {rating >= 2 ? (
          <SelectedStarIcon className="icon" />
        ) : (
          <NotSelectedStarIcon className="icon" />
        )}
        <label className="margin-top-small">
          Talvez. Como um ponto de partida.
        </label>
      </div>
      <div className="star" onClick={() => rateText(3)}>
        {rating >= 3 ? (
          <SelectedStarIcon className="icon" />
        ) : (
          <NotSelectedStarIcon className="icon" />
        )}
        <label className="margin-top-small">Usaria com alterações.</label>
      </div>
      <div className="star" onClick={() => rateText(4)}>
        {rating >= 4 ? (
          <SelectedStarIcon className="icon" />
        ) : (
          <NotSelectedStarIcon className="icon" />
        )}
        <label className="margin-top-small">Usaria mudando bem pouco.</label>
      </div>
      <div className="star" onClick={() => rateText(5)}>
        {rating >= 5 ? (
          <SelectedStarIcon className="icon" />
        ) : (
          <NotSelectedStarIcon className="icon" />
        )}
        <label className="margin-top-small">Opa se usaria! Copia e cola.</label>
      </div>
    </div>
  );
}

export default RateAgentComponent;

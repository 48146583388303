import React, { useContext } from "react";
import "./PaginationComponent.scss";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/solid";
import { CrudContext } from "../CrudArea/CrudArea";

function PaginationComponent({ total = 1 }) {
  const { page, perPage, setPage } = useContext(CrudContext);
  const totalPages = Math.ceil(total / perPage);

  if (totalPages === 1) {
    return null;
  }

  return (
    <div className="pagination-component">
      <button
        disabled={total < perPage}
        onClick={() => setPage(page > 1 ? page - 1 : page)}
      >
        <ArrowLeftIcon className="switch-page-icon left" /> Anterior
      </button>
      <div className="page-count">
        {Array.from({ length: totalPages }, (_, index) => (
          <span
            key={index}
            className={`page-selector ${index + 1 === page ? "active" : ""}`}
            onClick={() => setPage(index + 1)}
          >
            {index + 1}
          </span>
        ))}
      </div>
      <button
        disabled={total < perPage}
        onClick={() => setPage(page < totalPages ? page + 1 : page)}
      >
        Próximo
        <ArrowRightIcon className="switch-page-icon right" />
      </button>
    </div>
  );
}

export default PaginationComponent;

import React, { createContext, useContext, useEffect, useState } from "react";
import "./ClientTasksPage.scss";
import PageTitle from "../../components/PageTitle/PageTitle";
import Loader from "../../components/Loader/Loader";
import taskService from "../../services/taskService";
import loadErrorState from "../../assets/load-error-state.svg";
import emptyState from "../../assets/empty-state.svg";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import Pill from "../../components/Pill/Pill";
import Button, { ButtonTypes } from "../../components/Button/Button";
import CreateOrUpdateTaskModal from "../../components/CreateOrUpdateTaskModal/CreateOrUpdateTaskModal";
import {
  TaskStatus,
  getAreaText,
  getTaskDeliverableText,
} from "../../utils/models/taskUtils";
import { TaskPreviewSidePanel } from "../../components/TaskPreview/TaskPreview";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";
import { getDateFromEpoch } from "../../utils/javascriptUtils";
import userSession from "../../utils/userSession";
import { useNavigate } from "react-router-dom";

const TasksPageContext = createContext();

function ClientTasksPage() {
  return (
    <div className="page">
      <PageTitle
        title="Tarefas do cliente"
        description="Gerencie todas as tarefas que precisam ser entregues para esse cliente"
        className={"margin-bottom-extra-large"}
      />
      <TasksPageComponent />
    </div>
  );
}

function TasksPageComponent() {
  const [openCreateUpdateTaskModal, setOpenCreateUpdateTaskModal] =
    useState(false);
  const [reloadAllTasks, setReload] = useState(false);

  function reloadAllPageData() {
    setReload(!reloadAllTasks);
  }

  function openCreateUpdateModal() {
    setOpenCreateUpdateTaskModal(true);
  }

  function closeCreateUpdateModal() {
    setOpenCreateUpdateTaskModal(false);
  }

  const contextValue = {
    reloadAllPageData,
    reloadAllTasks,
  };

  return (
    <TasksPageContext.Provider value={contextValue}>
      <div>
        <CreateOrUpdateTaskModal
          isOpen={openCreateUpdateTaskModal}
          onClose={closeCreateUpdateModal}
          reloadData={reloadAllPageData}
        />

        <div className="actions-header">
          <Button onClick={openCreateUpdateModal}>Nova Tarefa</Button>
        </div>
        <div>
          <h2 className="margin-top-medium">Tarefas Planejadas</h2>
          <TasksTable status={TaskStatus.backlog} />
          <h2 className="margin-top-medium">Em andamento</h2>
          <TasksTable status={TaskStatus.inProgress} />
          <h2 className="margin-top-medium">Em revisão</h2>
          <TasksTable status={TaskStatus.review} />
          <h2 className="margin-top-medium">Finalizadas</h2>
          <TasksTable status={TaskStatus.done} />
        </div>
      </div>
    </TasksPageContext.Provider>
  );
}

function TasksTable({ status }) {
  const [data, setData] = useState([]);
  const [viewTask, setViewTask] = useState(null);
  const [selectedTask, setSelectedTask] = useState(null);
  const [taskIdToDelete, setOpenConfirmDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadError, setLoadError] = useState(false);
  const navigate = useNavigate();
  const [reload, setReload] = useState(false);
  const { reloadAllPageData, reloadAllTasks } = useContext(TasksPageContext);

  function reloadTableData() {
    setReload(!reload);
  }

  useEffect(() => {
    const fetchTasks = async (status) => {
      setLoadError(false);
      setLoading(true);

      const result = await taskService.getTasks({
        clientId: userSession.getCurrentClient().id,
        status: status,
      });

      setLoading(false);

      if (!result) {
        setLoadError(true);
      }

      setData(result);
    };

    fetchTasks(status);
  }, [reloadAllTasks, reload]);

  function openTaskSidePanel(task) {
    setViewTask(true);
    setSelectedTask(task);
  }

  function closeDeleteConfirmationModal() {
    setOpenConfirmDelete(false);
  }

  async function handleDelete() {
    setLoading(true);
    await taskService.deleteTask(taskIdToDelete);
    setLoading(false);
    reloadTableData();
    closeDeleteConfirmationModal();
  }

  if (loadError) {
    return (
      <div className="empty-table">
        <img src={loadErrorState} alt="Logo" />
      </div>
    );
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <TaskPreviewSidePanel
        task={selectedTask}
        isOpen={viewTask}
        onClose={() => setViewTask(false)}
        reloadData={reloadTableData}
      />

      <ConfirmationModal
        isOpen={taskIdToDelete}
        onClose={closeDeleteConfirmationModal}
        title={"Excluir Tarefa"}
        message={"Tem certeza que deseja excluir esta Tarefa?"}
        onConfirm={handleDelete}
      />

      <div>
        <table className="primary margin-top-medium">
          <thead>
            <tr>
              <th className="column-1">Data de entrega</th>
              <th className="column-1">Área</th>
              <th className="column-2">Peça</th>
              <th className="column-5">Assunto</th>
              <th className="column-2">Marcadores da tarefa</th>
              <th className="column-1"></th>
              <th className="column-1"></th>
            </tr>
          </thead>

          {!data?.tasks?.length ? (
            <tbody className="empty-table">
              <img src={emptyState} alt="Logo" />
            </tbody>
          ) : (
            <tbody>
              {data.tasks?.map((task, taskIdx) => {
                return (
                  <tr
                    key={taskIdx}
                    onClick={() => navigate(`/tarefa/${task.id}`)}
                  >
                    <td className="column-1">
                      {getDateFromEpoch(task.dueDate)}
                    </td>
                    <td className="column-1">{getAreaText(task)}</td>
                    <td className="column-2">{getTaskDeliverableText(task)}</td>
                    <td className="column-5 truncate">
                      <div className="font-size-small">
                        {task.topic && task.topic != "\n"
                          ? task.topic
                          : "Ainda não há um Assunto definido."}
                      </div>
                    </td>
                    <td className="column-2">
                      {task.tags?.map((tag, idx) => {
                        return (
                          <Pill
                            key={idx}
                            value={tag}
                            className="margin-right-small"
                          />
                        );
                      })}
                    </td>

                    <td className="column-1"></td>
                    <td className="column-1 align-right">
                      <MagnifyingGlassIcon
                        onClick={(e) => {
                          e.stopPropagation();
                          openTaskSidePanel(task);
                        }}
                        type={ButtonTypes.secondary}
                        className="icon"
                      >
                        Mais detalhes
                      </MagnifyingGlassIcon>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
      </div>
    </>
  );
}

export default ClientTasksPage;

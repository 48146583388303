import React, { useContext, useState } from "react";
import "./LoginPage.scss";
import userService from "../../services/userService";
import userSession from "../../utils/userSession";
import { AppContext, navbarOptions } from "../../App";
import { validateEmail } from "../../utils/inputValidations";
import Card from "../../components/Card/Card";
import Button, { ButtonSizes } from "../../components/Button/Button";
import { Input } from "../../components/Input/Input";
import Loader from "../../components/Loader/Loader";
import { getSubdomain } from "../../utils/javascriptUtils";

function LoginPage() {
  const { changeNavbar } = useContext(AppContext);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [subdomain, setSubdomain] = useState("");
  const [loading, setLoading] = useState(false);
  const currentSubdomain = getSubdomain(window.location.href);

  const handleLogin = async (event) => {
    event.preventDefault();

    setFormSubmitted(true);

    if (!formIsValid()) {
      return;
    }

    setLoading(true);
    const data = await userService.login(
      email,
      password,
      currentSubdomain || subdomain,
    );
    setLoading(false);
    userSession.setSession(data);
    changeNavbar(navbarOptions.agency);
  };

  function formIsValid() {
    if (!validateEmail(email).isValid) {
      return false;
    }
    if (!password) {
      return false;
    }

    return true;
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="page center-content">
      <Card title="Bem vindo(a)!">
        <form onSubmit={handleLogin}>
          {!currentSubdomain && (
            <Input
              label="Agência"
              value={subdomain}
              onChange={setSubdomain}
              validationError={
                formSubmitted && !subdomain && "Preencha o nome da agência"
              }
              className="margin-bottom-small"
            />
          )}
          <Input
            label="Email"
            type="email"
            value={email}
            onChange={setEmail}
            validationError={
              formSubmitted &&
              !validateEmail(email).isValid &&
              validateEmail(email).errorMessage
            }
          />
          <Input
            label="Senha"
            type="password"
            value={password}
            onChange={setPassword}
            validationError={formSubmitted && !password && "Preencha sua senha"}
            className="margin-top-small"
          />

          <Button
            onClick={handleLogin}
            className="margin-top-medium"
            size={ButtonSizes.large}
          >
            Entrar
          </Button>

          <div className="margin-top-medium">
            <a href="/redefinir-senha">Esqueci minha senha</a>
          </div>
        </form>
      </Card>
    </div>
  );
}

export default LoginPage;

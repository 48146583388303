import userSession from "../../utils/userSession";

function UserRestrictedArea({
  children,
  allowedUserRoles = [UserRoles.ADMIN],
}) {
  const currentUser = userSession.getCurrentUser();

  if (allowedUserRoles.includes(currentUser.role)) {
    return children;
  }

  return null;
}

export const UserRoles = {
  ADMIN: "admin",
  USER: "user",
};

export default UserRestrictedArea;

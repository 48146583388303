import React from "react";
import "./PageTitle.scss";

function PageTitle({ title, description, className }) {
  return (
    <div className={`page-title ${className}`}>
      <h1>{title}</h1>
      <hr />
      {description && <p>{description}</p>}
    </div>
  );
}

export default PageTitle;

import React, { useContext, useState } from "react";
import "./ClientSandboxPage.scss";
import { navbarOptions, AppContext } from "../../App";
import TaskSimpleForm from "../../components/TaskSimpleForm/TaskSimpleForm";
import RateAgentComponent, {
  ClientEnvs,
} from "../../components/RateAgentComponent/RateAgentComponent";
import ReactMarkdown from "react-markdown";

function ClientSandboxPage() {
  const [text, setText] = useState("");
  const [taskDetails, setTaskDetails] = useState(null);

  return (
    <div className="page">
      <div className="sandbox-page-title margin-bottom-large">
        <h1>Teste e Avalie seu Agente</h1>
      </div>
      <div className="sandbox-container">
        <div className="task-card-container">
          <TaskSimpleForm setText={setText} setTaskDetails={setTaskDetails} />
        </div>
        {text && (
          <>
            <div className="generated-text-container">
              <ReactMarkdown>{text}</ReactMarkdown>
            </div>
            <div className="evaluation-card-container">
              <RateAgentComponent
                clientEnv={ClientEnvs.sandbox}
                taskDetails={taskDetails}
                evaluatedText={text}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default ClientSandboxPage;

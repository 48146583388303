import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import "./TaskPage.scss";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import taskService from "../../services/taskService";
import { AppContext, navbarOptions } from "../../App";
import {
  BackwardIcon,
  BoltIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline";
import {
  CheckCircleIcon,
  QuestionMarkCircleIcon,
  BoltIcon as SelectedBoltIcon,
  SparklesIcon,
} from "@heroicons/react/24/solid";
import TaskPreview from "../../components/TaskPreview/TaskPreview";
import QuillTextEditor from "../../components/QuillTextEditor/QuillTextEditor";
import Quill from "quill";
import ReactMarkdown from "react-markdown";
import Button, {
  ButtonSizes,
  ButtonTypes,
} from "../../components/Button/Button";
import copyAgentService from "../../services/copyAgentService";
import AgentChatWindow from "../../components/AgentChatWindow/AgentChatWindow";
import {
  TaskStatus,
  taskFields,
  taskFieldsText,
  taskReviewStatuses,
  taskSubmissionStatuses,
} from "../../utils/models/taskUtils";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";
import Modal from "../../components/Modal/Modal";
import { getDatetimeFromEpoch } from "../../utils/javascriptUtils";

const Delta = Quill.import("delta");

export const TaskContext = createContext();

function TaskPage() {
  const { task_id } = useParams();
  const [task, setTask] = useState(null);
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(false);
  const { changeNavbar } = useContext(AppContext);
  const navigate = useNavigate();
  const [selectedField, setSelectedField] = useState(taskFields.topic);
  const [agentHelper, setAgentHelper] = useState(false);
  const [currentAgentConversation, setCurrentAgentConversation] =
    useState(null);
  const [openHistoryModal, setOpenHistoryModal] = useState(false);

  const allowEdit =
    task?.status === TaskStatus.backlog ||
    task?.status === TaskStatus.inProgress;

  async function autoGenerateField(field) {
    setSelectedField(field);

    const payload = {
      taskId: task.id,
      field: field,
    };

    const result = await copyAgentService.generateContent(payload);

    if (!result?.answer) {
      return;
    }

    setTask((prevTask) => ({
      ...prevTask,
      [field]: result.answer,
    }));
  }

  const contextValue = {
    task,
    setTask,
    reloadTaskFromPage,
    selectedField,
    setSelectedField,
    agentHelper,
    setAgentHelper,
    currentAgentConversation,
    setCurrentAgentConversation,
    allowEdit,
    openHistoryModal,
    setOpenHistoryModal,
    autoGenerateField,
  };

  changeNavbar(navbarOptions.none);

  if (agentHelper && !allowEdit) {
    setAgentHelper(false);
  }

  function navigateBackToClientTasks() {
    changeNavbar(navbarOptions.client);
    navigate(`/cliente/${task.clientId}/tarefas`);
  }

  function reloadTaskFromPage() {
    setReload(!reload);
  }

  useEffect(() => {
    async function getTask() {
      setLoading(true);

      const result = await taskService.getTaskById(task_id);

      setLoading(false);

      if (!result) {
        return;
      }

      setTask(result);
    }
    getTask();
  }, [task_id, reload]);

  if (loading) {
    return <Loader />;
  }

  if (!task) {
    return (
      <div className="page">
        <h1>Ops, não encontramos essa página!</h1>
      </div>
    );
  }

  return (
    <TaskContext.Provider value={contextValue}>
      <div className="page center-content">
        <div className="back-button" onClick={navigateBackToClientTasks}>
          <BackwardIcon className="icon margin-right-small" />
          Sair
        </div>

        <div className="task-work-area margin-top-medium">
          <div className="task-timeline-container margin-bottom-medium">
            <TaskTimeline currentStatus={task.status} />
            <QuestionMarkCircleIcon
              className="timeline-history-icon"
              onClick={() => setOpenHistoryModal(true)}
            />
          </div>

          <div className="icons-container margin-bottom-medium">
            <div
              className={`icon-box ${!agentHelper && "selected"}`}
              onClick={(e) => {
                e.stopPropagation();
                setAgentHelper(false);
              }}
            >
              <PencilSquareIcon className="icon margin-right-extra-small" />{" "}
              Escrever
            </div>
            <div
              className={`icon-box ${agentHelper && "selected"} margin-left-small`}
              onClick={(e) => {
                e.stopPropagation();
                setAgentHelper(true);
              }}
            >
              {agentHelper ? (
                <SparklesIcon className="icon margin-right-extra-small" />
              ) : (
                <SparklesIcon
                  className="icon margin-right-extra-small"
                  onClick={(e) => {
                    e.stopPropagation();
                    setAgentHelper(true);
                  }}
                />
              )}
              Brainstorming
            </div>
          </div>

          <div className="central-container">
            <div className="task-fields-container">
              <TaskEditingFields />
            </div>
            <div className="task-editor-container">
              {agentHelper ? <AgentChatHelp /> : <TaskEditor />}
            </div>
            <div className="task-preview-container">
              <TaskPreview task={task} reloadData={reloadTaskFromPage} />
            </div>
          </div>
        </div>
      </div>
    </TaskContext.Provider>
  );
}

const TaskTimeline = () => {
  const { task, reloadTaskFromPage, openHistoryModal, setOpenHistoryModal } =
    useContext(TaskContext);
  const [loading, setLoading] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [confirmTitle, setConfirmTitle] = useState("");
  const [confirmMessage, setConfirmMessage] = useState("");
  const [confirmAction, setConfirmAction] = useState(false);
  const topicIsDefined = task.topic && task.topic != "\n";
  const textIsDefined = task.text && task.text != "\n";

  const lastSubmission =
    task.submissions?.length > 0
      ? task.submissions[task.submissions.length - 1]
      : null;
  const lastSubmissionStatus = lastSubmission
    ? `${taskSubmissionStatuses[lastSubmission.status]} por ${lastSubmission.user.name}`
    : null;

  const lastReview =
    task.reviews?.length > 0 ? task.reviews[task.reviews.length - 1] : null;
  const lastReviewStatus = lastReview
    ? `${taskReviewStatuses[lastReview.status]} por ${lastReview.user.name}`
    : null;

  async function updateTaskStatus(status) {
    setLoading(true);
    const result = await taskService.updateTaskStatus(task.id, {
      status: status,
    });
    setLoading(false);

    if (!result?.id) {
      return;
    }

    reloadTaskFromPage();
  }

  function openConfirmationModalForAction(title, message, action) {
    setConfirmTitle(title);
    setConfirmMessage(message);
    setConfirmAction(() => action);
    setOpenConfirmationModal(true);
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Modal
        isOpen={openHistoryModal}
        onClose={() => setOpenHistoryModal(false)}
        title="Histórico da Tarefa"
      >
        <TaskHistoryComponent />
      </Modal>

      <div className="timeline">
        <div className="timeline-item margin-bottom-small">
          <CheckCircleIcon className="timeline-check-icon" />
          <span>Tarefa Planejada</span>
          {task.requester?.name && (
            <div className="responsible">Criada por {task.requester?.name}</div>
          )}
          {task.status === TaskStatus.backlog && topicIsDefined && (
            <Button
              size={ButtonSizes.small}
              className="margin-top-small"
              onClick={() =>
                openConfirmationModalForAction(
                  "Iniciar Tarefa",
                  "Você irá começar a trabalhar na Tarefa agora?",
                  () => updateTaskStatus(TaskStatus.inProgress),
                )
              }
            >
              Iniciar Tarefa
            </Button>
          )}
        </div>

        <div className="timeline-item margin-bottom-small">
          {task.status === TaskStatus.review ||
          task.status === TaskStatus.done ? (
            <CheckCircleIcon className="timeline-check-icon" />
          ) : (
            <div
              className={`timeline-dot ${task.status === TaskStatus.inProgress && "active"}`}
            />
          )}
          <span>Desenvolvimento</span>
          {lastSubmissionStatus && (
            <div className="responsible">{lastSubmissionStatus}</div>
          )}

          {task.status === TaskStatus.inProgress && (
            <div className="actions">
              <Button
                size={ButtonSizes.small}
                className="margin-top-small"
                type={ButtonTypes.secondary}
                onClick={() =>
                  openConfirmationModalForAction(
                    "Pausar Tarefa",
                    "Você precisa parar o desenvolvimento dessa Tarefa? Se você pausar, ela voltará para a etapa de Planejamento.",
                    () => updateTaskStatus(TaskStatus.backlog),
                  )
                }
              >
                Voltar à Fila
              </Button>

              <Button
                size={ButtonSizes.small}
                className="margin-top-small"
                disabled={!topicIsDefined || !textIsDefined}
                onClick={() =>
                  openConfirmationModalForAction(
                    "Finalizar e Enviar para Revisão",
                    "A Tarefa está finalizada?",
                    () => updateTaskStatus(TaskStatus.review),
                  )
                }
              >
                Finalizar
              </Button>
            </div>
          )}
        </div>

        <div className="timeline-item margin-bottom-small">
          {task.status === TaskStatus.done ? (
            <CheckCircleIcon className="timeline-check-icon" />
          ) : (
            <div
              className={`timeline-dot ${task.status === TaskStatus.review && "active"}`}
            />
          )}
          <span>Revisão</span>
          {lastReviewStatus && (
            <div className="responsible">{lastReviewStatus}</div>
          )}
          {task.status === TaskStatus.review && (
            <div className="actions">
              <Button
                size={ButtonSizes.small}
                className="margin-top-small"
                type={ButtonTypes.danger}
                onClick={() =>
                  openConfirmationModalForAction(
                    "Reprovar Tarefa",
                    "A Tarefa ainda precisa de mudanças?",
                    () => updateTaskStatus(TaskStatus.inProgress),
                  )
                }
              >
                Reprovar
              </Button>
              <Button
                size={ButtonSizes.small}
                className="margin-top-small"
                onClick={() =>
                  openConfirmationModalForAction(
                    "Aprovar Tarefa",
                    "Você aprova para publicação?",
                    () => updateTaskStatus(TaskStatus.done),
                  )
                }
              >
                Aprovar
              </Button>
            </div>
          )}
        </div>

        <div className="timeline-item margin-bottom-small">
          {task.status === TaskStatus.done ? (
            <CheckCircleIcon className="timeline-check-icon" />
          ) : (
            <div className="timeline-dot" />
          )}
          <span>Concluída</span>
          {task.status === TaskStatus.done && (
            <Button
              size={ButtonSizes.small}
              className="margin-top-small"
              onClick={() =>
                openConfirmationModalForAction(
                  "Reabrir Tarefa",
                  "Esta Tarefa já foi finalizada. Gostaria mesmo assim de reabri-la?",
                  () => updateTaskStatus(TaskStatus.inProgress),
                )
              }
            >
              Reabrir Tarefa
            </Button>
          )}
        </div>
      </div>
      <ConfirmationModal
        isOpen={openConfirmationModal}
        onClose={() => setOpenConfirmationModal(false)}
        title={confirmTitle}
        message={confirmMessage}
        onConfirm={confirmAction}
      />
    </>
  );
};

function TaskHistoryComponent() {
  const { task } = useContext(TaskContext);

  const taskReviewsWithStatusMessage = task.reviews.map((review) => ({
    ...review,
    statusMessage: `${taskReviewStatuses[review.status]} por ${review.user.name}`,
  }));

  const taskSubmissionsWithStatusMessage = task.submissions.map(
    (submission) => ({
      ...submission,
      statusMessage: `${taskSubmissionStatuses[submission.status]} por ${submission.user.name}`,
    }),
  );

  const history = [
    ...taskReviewsWithStatusMessage,
    ...taskSubmissionsWithStatusMessage,
  ].sort((a, b) => a.createdAt - b.createdAt);

  return (
    <div className="task-history-container">
      <div className="history-item margin-bottom-small">
        {getDatetimeFromEpoch(task.createdAt)} Criada por {task.requester.name}
      </div>

      {history.map((item, index) => (
        <div key={index} className="history-item margin-bottom-small">
          {getDatetimeFromEpoch(item.createdAt)} {item.statusMessage}
        </div>
      ))}
    </div>
  );
}

function TaskEditor() {
  const quillRef = useRef();
  const { task, selectedField, reloadTaskFromPage, allowEdit } =
    useContext(TaskContext);
  const content = task[selectedField];
  const [loading, setLoading] = useState(false);

  async function updateTaskField() {
    const text = quillRef.current.getText();

    const payload = {
      [selectedField]: text,
    };

    setLoading(true);

    const result = await taskService.updateTask(task.id, payload);

    setLoading(false);

    if (!result?.id) {
      return;
    }

    reloadTaskFromPage();
  }

  async function insertTextWithAI(index, action) {
    const payload = {
      insertionIndex: index,
      field: selectedField,
      userPrompt: action,
      currentText: quillRef.current.getText(),
      taskId: task.id,
    };

    const result = await copyAgentService.insertText(payload);

    return result;
  }

  async function modifyTextWithAI(selectedText, action) {
    const payload = {
      modifyingText: selectedText,
      modifyingField: selectedField,
      modifyingRequest: action,
      currentText: quillRef.current.getText(),
      taskId: task.id,
    };

    const result = await copyAgentService.modifyText(payload);

    return result;
  }

  if (loading) {
    return <Loader />;
  }

  if (!allowEdit) {
    return (
      <div className="editor-container text-only">
        <ReactMarkdown>{content}</ReactMarkdown>
      </div>
    );
  }

  return (
    <div className="editor-container">
      <QuillTextEditor
        ref={quillRef}
        className="editor"
        defaultValue={new Delta().insert(content)}
        enableAIHelper={true}
        AIinsertTextFunction={insertTextWithAI}
        modifyTextWithAI={modifyTextWithAI}
      />
      {allowEdit && (
        <div>
          <Button className="full-width" onClick={updateTaskField}>
            Salvar
          </Button>
        </div>
      )}
    </div>
  );
}

function AgentChatHelp() {
  const {
    task,
    currentAgentConversation,
    setCurrentAgentConversation,
    reloadTaskFromPage,
  } = useContext(TaskContext);
  const [openModal, setOpenModal] = useState(false);
  const initialMessage = `Sou capaz de gerar ideias de **Assunto**, **Texto** ou **Brief de Arte**. O que quer fazer?`;
  const [agentSuggestion, setAgentSuggestion] = useState(null);
  const quillRef = useRef();
  const [loading, setLoading] = useState(false);

  async function updateTaskField(field) {
    const text = quillRef.current.getText();

    const payload = {
      [field]: text,
    };

    setLoading(true);

    const result = await taskService.updateTask(task.id, payload);

    setLoading(false);

    if (!result?.id) {
      return;
    }

    reloadTaskFromPage();
  }

  function selectAgentSuggestion(value) {
    setAgentSuggestion(value);
    setOpenModal(true);
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Modal
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
        title={`Atualizar '${taskFieldsText[agentSuggestion?.key]}'`}
      >
        <QuillTextEditor
          ref={quillRef}
          className="agent-suggestion-editor"
          defaultValue={new Delta().insert(agentSuggestion?.text)}
        />
        <div className="agent-modal-actions margin-top-large">
          <Button onClick={() => updateTaskField(agentSuggestion.key)}>
            Salvar
          </Button>
          <Button
            type={ButtonTypes.secondary}
            onClick={() => setOpenModal(false)}
          >
            Cancelar
          </Button>
        </div>
      </Modal>

      <AgentChatWindow
        initialMessage={initialMessage}
        agentService={copyAgentService.taskHelper}
        taskId={task.id}
        previousConversation={currentAgentConversation}
        updatePreviousConversation={setCurrentAgentConversation}
        onItemClick={selectAgentSuggestion}
        extraPaddingTop={true}
      />
    </>
  );
}

function TaskEditingFields() {
  const {
    task,
    selectedField,
    setSelectedField,
    setAgentHelper,
    autoGenerateField,
  } = useContext(TaskContext);

  const topicIsDefined = task.topic && task.topic != "\n";
  const disableFields = !topicIsDefined;
  const [loading, setLoading] = useState(false);

  function selectField(field) {
    if (selectedField === field) {
      return;
    }
    setSelectedField(field);
    setAgentHelper(false);
  }

  async function generateContent(field) {
    setLoading(true);
    await autoGenerateField(field);
    setLoading(false);
  }

  return (
    <>
      <div
        className={`task-field-container ${selectedField === taskFields.topic && "selected"}`}
        onClick={() => {
          if (!loading) {
            selectField(taskFields.topic);
          }
        }}
      >
        <label>Assunto</label>
        <div className="content-container">
          <div className="content">
            {loading && selectedField === taskFields.topic ? (
              <span className="loader"></span>
            ) : topicIsDefined ? (
              <ReactMarkdown>{task.topic}</ReactMarkdown>
            ) : (
              <b>
                <i>É necessário definir um Assunto.</i>
              </b>
            )}
          </div>
        </div>
        <div className="content-actions">
          <SparklesIcon
            className={`icon surprise-icon`}
            title="Surpreenda-me!"
            onClick={() => {
              if (!loading) {
                generateContent(taskFields.topic);
              }
            }}
          />
        </div>
      </div>
      <div
        className={`task-field-container ${disableFields && "disabled"} ${selectedField === taskFields.text && "selected"}`}
        onClick={() => {
          if (!disableFields && !loading) {
            selectField(taskFields.text);
          }
        }}
      >
        <label>Texto da Peça</label>
        <div className="content-container">
          <div className="content">
            {loading && selectedField === taskFields.text ? (
              <span className="loader"></span>
            ) : task.text && task.text != "\n" ? (
              <ReactMarkdown>{task.text}</ReactMarkdown>
            ) : (
              <i>Ainda não há um Texto escrito para essa tarefa.</i>
            )}
          </div>
        </div>
        <div className="content-actions">
          <SparklesIcon
            className={`icon surprise-icon ${disableFields && "disabled"}`}
            title="Surpreenda-me!"
            onClick={() => {
              if (!disableFields && !loading) {
                generateContent(taskFields.text);
              }
            }}
          />
        </div>
      </div>
      <div
        className={`task-field-container ${disableFields && "disabled"} ${selectedField === taskFields.briefDesign && "selected"}`}
        onClick={() => {
          if (!disableFields && !loading) {
            selectField(taskFields.briefDesign);
          }
        }}
      >
        <label>Brief de Arte</label>
        <div className="content-container">
          <div className="content">
            {loading && selectedField === taskFields.briefDesign ? (
              <span className="loader"></span>
            ) : task.briefDesign && task.briefDesign != "\n" ? (
              <ReactMarkdown>{task.briefDesign}</ReactMarkdown>
            ) : (
              <i>Não há um Brief de Arte definido para essa tarefa.</i>
            )}
          </div>
        </div>
        <div className="content-actions">
          <SparklesIcon
            className={`icon surprise-icon ${disableFields && "disabled"}`}
            title="Surpreenda-me!"
            onClick={() => {
              if (!disableFields && !loading) {
                generateContent(taskFields.briefDesign);
              }
            }}
          />
        </div>
      </div>
    </>
  );
}

export default TaskPage;

import { toast } from "react-toastify";
import {
  BASE_URL,
  apiDefaultErrorHandler,
  checkApiResponse,
  httpServiceGet,
  httpServiceCreate,
  httpServiceUpdate,
  httpServiceDelete,
  updatedSuccessfullyToast,
  payloadTypes,
} from "../utils/serviceUtils";
import { mapKeysToCamelCase } from "../utils/javascriptUtils";
import userSession from "../utils/userSession";

const login = async (username, password, subdomain) => {
  try {
    const response = await fetch(`${BASE_URL}/token`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams({
        username: username,
        password: password,
        subdomain: subdomain,
      }),
    });

    await checkApiResponse(response);

    let data = await response.json();
    data = mapKeysToCamelCase(data);

    if (!data?.accessToken || !data?.user) {
      throw new Error();
    }

    return data;
  } catch (error) {
    loginSignupErrorHandler(error);
  }
};

const signup = async (payload) => {
  try {
    const formData = new FormData();

    for (let key in payload) {
      formData.append(key, payload[key]);
    }

    const response = await fetch(`${BASE_URL}/signup`, {
      method: "PUT",
      headers: {},
      body: formData,
    });

    await checkApiResponse(response);

    let data = await response.json();

    if (!data?.accessToken || !data?.user) {
      throw new Error();
    }

    return data;
  } catch (error) {
    loginSignupErrorHandler(error);
  }
};

const selfEdit = async (payload) => {
  try {
    const formData = new FormData();

    for (let key in payload) {
      formData.append(key, payload[key]);
    }

    const response = await fetch(`${BASE_URL}/user/me`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${userSession.getToken()}`,
      },
      body: formData,
    });

    await checkApiResponse(response);

    let data = await response.json();

    if (!data?.id) {
      throw new Error();
    }

    updatedSuccessfullyToast();

    return data;
  } catch (error) {
    selfEditErrorHandler(error);
    return null;
  }
};

const getUsers = async ({ ...props }) => {
  try {
    const url = new URL(`${BASE_URL}/user`);
    const data = await httpServiceGet({ url, ...props });
    return data;
  } catch (error) {
    apiDefaultErrorHandler(error);
    return null;
  }
};

const createUser = async (payload) => {
  try {
    const url = new URL(`${BASE_URL}/user`);
    const data = await httpServiceCreate(url, payload);
    return data;
  } catch (error) {
    apiDefaultErrorHandler(error);
    return null;
  }
};

const updateUser = async (userId, payload) => {
  try {
    const url = new URL(`${BASE_URL}/user/${userId}`);
    const data = await httpServiceUpdate(url, payload);
    return data;
  } catch (error) {
    apiDefaultErrorHandler(error);
    return null;
  }
};

const deleteUser = async (userId) => {
  try {
    const url = new URL(`${BASE_URL}/user/${userId}`);
    await httpServiceDelete(url);
    return true;
  } catch (error) {
    apiDefaultErrorHandler(error);
    return false;
  }
};

const verifyToken = async (token) => {
  try {
    const url = new URL(`${BASE_URL}/signup/token/verify/${token}`);
    const response = await fetch(url);
    await checkApiResponse(response);
    const data = await response.json();
    return { data: data, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};

const verifyRecoverPasswordToken = async (token) => {
  try {
    const url = new URL(`${BASE_URL}/recover_password/token/verify/${token}`);
    const response = await fetch(url);
    await checkApiResponse(response);
    const data = await response.json();
    return { data: data, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
};

const recoverPassword = async (payload) => {
  try {
    const url = new URL(`${BASE_URL}/recover_password`);
    const data = await httpServiceUpdate(
      url,
      payload,
      payloadTypes.JSON,
      false,
    );
    return data;
  } catch (error) {
    apiDefaultErrorHandler(error);
    return null;
  }
};

const resetPassword = async (payload) => {
  try {
    const url = new URL(`${BASE_URL}/reset_password`);
    const data = await httpServiceUpdate(url, payload);
    return data;
  } catch (error) {
    apiDefaultErrorHandler(error);
    return null;
  }
};

const search = async ({ ...props }) => {
  try {
    const url = new URL(`${BASE_URL}/user/search`);
    const data = await httpServiceGet({ url, ...props });
    return data;
  } catch (error) {
    apiDefaultErrorHandler(error);
  }
};

const resendEmail = async (userId) => {
  try {
    const url = new URL(`${BASE_URL}/user/${userId}/resend_invitation`);
    const headers = {
      Authorization: `Bearer ${userSession.getToken()}`,
    };

    const response = await fetch(url, {
      method: "POST",
      headers: headers,
    });

    await checkApiResponse(response);

    toast.success("Email reenviado com sucesso.");

    return true;
  } catch (error) {
    toast.error("Ocorreu um problema. Por favor, tente novamente mais tarde.");
  }
};

const loginSignupErrorHandler = (error) => {
  if (error.status === 403 || error.status === 422) {
    toast.error("Usuário ou senha inválidos.");
    return;
  }
  toast.error("Ocorreu um problema. Por favor, tente novamente mais tarde.");
};

const selfEditErrorHandler = (error) => {
  if (error.status === 403) {
    toast.error("Senha incorreta.");
    return;
  }
  toast.error("Ocorreu um problema. Por favor, tente novamente mais tarde.");
};

const userService = {
  login,
  signup,
  getUsers,
  createUser,
  updateUser,
  deleteUser,
  verifyToken,
  verifyRecoverPasswordToken,
  recoverPassword,
  resetPassword,
  search,
  selfEdit,
  resendEmail,
};

export default userService;

import React, { useEffect, useState } from "react";
import "./ManageClientUsersModal.scss";
import clientUserService from "../../services/clientUserService";
import Modal from "../Modal/Modal";
import { Input } from "../Input/Input";
import { UserAvatar, UserAvatarSizes } from "../UserAvatar/UserAvatar";
import emptyState from "../../assets/empty-state.svg";
import { StarIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { StarIcon as SelectedStarIcon } from "@heroicons/react/24/solid";
import Loader from "../Loader/Loader";
import { validateEmail } from "../../utils/inputValidations";
import userService from "../../services/userService";

function ManageClientUsersModal({
  clientId,
  isOpen,
  onClose,
  title = "Gerenciar usuários do cliente",
}) {
  const [clientUsers, setClientUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getClientUsers = async () => {
      const maxUsers = 100;
      setLoading(true);

      const result = await clientUserService.getClientUsers({
        clientId: clientId,
        perPage: maxUsers,
      });

      setLoading(false);

      if (!result?.clientUsers) {
        setClientUsers([]);
        return;
      }

      setClientUsers(result.clientUsers);
    };

    getClientUsers();
  }, [clientId]);

  async function toggleClientUserRole(clientUser) {
    const newRole = clientUser.role === "admin" ? "user" : "admin";

    setLoading(true);

    const result = await clientUserService.updateClientUser(clientUser.id, {
      role: newRole,
    });

    setLoading(false);

    if (!result?.id) {
      return;
    }

    const updatedClientUsers = clientUsers.map((clientUser) => {
      if (clientUser.id === result.id) {
        return result;
      }

      return clientUser;
    });

    setClientUsers(updatedClientUsers);
  }

  async function deleteUserClient(clientUserId) {
    setLoading(true);

    const result = await clientUserService.deleteClientUser(clientUserId);

    setLoading(false);

    if (!result) {
      return;
    }

    const updatedClientUsers = clientUsers.filter(
      (clientUser) => clientUser.id !== clientUserId,
    );

    setClientUsers(updatedClientUsers);
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title}>
      <SearchAndAddUsers
        clientId={clientId}
        clientUsers={clientUsers}
        setClientUsers={setClientUsers}
      />

      {loading && <Loader />}

      {!loading && clientUsers.length === 0 && (
        <div className="empty-table">
          <img src={emptyState} alt="Logo" />
        </div>
      )}

      {!loading && clientUsers.length > 0 && (
        <div className="client-users-list margin-top-small">
          {clientUsers?.map((clientUser, idx) => {
            return (
              <div key={idx} className="client-user-info">
                <div className="user-details">
                  <UserAvatar
                    user={clientUser.user}
                    size={UserAvatarSizes.large}
                    className="margin-right-small"
                  />
                  {clientUser.user.isPending ? (
                    <p>
                      {clientUser.user.email} <i>(Convite pendente)</i>
                    </p>
                  ) : (
                    <p>{clientUser.user.name}</p>
                  )}
                </div>
                <div className="user-actions">
                  {clientUser.role === "admin" ? (
                    <SelectedStarIcon
                      className="action-icon margin-right-small"
                      color="blue"
                      onClick={() => toggleClientUserRole(clientUser)}
                    />
                  ) : (
                    <StarIcon
                      className="action-icon margin-right-small"
                      onClick={() => toggleClientUserRole(clientUser)}
                    />
                  )}
                  <XMarkIcon
                    className="action-icon margin-right-small"
                    onClick={() => deleteUserClient(clientUser.id)}
                  />
                </div>
              </div>
            );
          })}
        </div>
      )}
    </Modal>
  );
}

function SearchAndAddUsers({ clientId, clientUsers, setClientUsers }) {
  const [search, setSearch] = useState("");
  const [usersFound, setUsersFound] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (!search) {
        setUsersFound([]);
        return;
      }

      const result = await userService.search({
        notClientId: clientId,
        search,
      });

      if (!result?.users || result.users.length === 0) {
        setUsersFound([]);
        return;
      }

      setUsersFound(result.users);
    };

    fetchData();
  }, [search]);

  function userIsAlreadyAdded(clientUsers, userId, email) {
    if (userId) {
      return clientUsers.some((clientUser) => clientUser.user.id === userId);
    }

    if (email) {
      return clientUsers.some((clientUser) => clientUser.user.email === email);
    }

    return false;
  }

  async function addUser({ userId = "", email = "" }) {
    if (userIsAlreadyAdded(clientUsers, userId, email)) {
      setSearch("");
      return;
    }

    let payload = { client_id: clientId, role: "user" };

    if (userId) {
      payload.user_id = userId;
    } else if (email) {
      payload.email = email;
    }

    setLoading(true);

    const result = await clientUserService.createClientUser(payload);

    setLoading(false);

    if (!result?.id) {
      return;
    }

    setClientUsers([...clientUsers, result]);
    setSearch("");
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Input
        value={search}
        onChange={setSearch}
        placeholder="Selecione um usuário"
        className="margin-top-medium"
        validationError={
          search &&
          usersFound.length === 0 &&
          !validateEmail(search).isValid &&
          "Insira um email válido"
        }
      />

      {search && (
        <div className="users-invite-list">
          {usersFound.length === 0 ? (
            <div
              className="invite-new-user"
              onClick={() => addUser({ email: search })}
            >
              Convide uma nova pessoa por email
            </div>
          ) : (
            <>
              {usersFound?.map((user, idx) => {
                return (
                  <div
                    key={idx}
                    className="user-info"
                    onClick={() => addUser({ userId: user.id })}
                  >
                    <UserAvatar
                      user={user}
                      size={UserAvatarSizes.large}
                      className="margin-right-small"
                    />
                    {user.isPending ? (
                      <p>
                        {user.email} <i>(Convite pendente)</i>
                      </p>
                    ) : (
                      <p>{user.name}</p>
                    )}
                  </div>
                );
              })}
            </>
          )}
        </div>
      )}
    </>
  );
}

export default ManageClientUsersModal;

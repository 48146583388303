import React from "react";
import "./ActionsDropdown.scss";
import { XMarkIcon } from "@heroicons/react/24/solid";

function ActionsDropdown({ closeActionsDropdown, options }) {
  return (
    <>
      <div
        className="dropdown-overlay"
        onMouseDown={closeActionsDropdown}
      ></div>
      <div className="dropdown-menu" onMouseDown={(e) => e.stopPropagation()}>
        <div className="close-action" onClick={closeActionsDropdown}>
          <XMarkIcon className="close-icon" />
        </div>

        <ul>
          {options.map((option, idx) => (
            <li key={idx} onClick={option.onClick}>
              {option.name}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}

export default ActionsDropdown;

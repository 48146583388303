import React from "react";
import { Link } from "react-router-dom";
import "./Navbar.scss";
import {
  HomeIcon,
  UserCircleIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import UserRestrictedArea from "../UserRestrictedArea/UserRestrictedArea";
import userSession from "../../utils/userSession";
import noLogo from "../../assets/no-logo.jpg";

function AgencyNavbar() {
  const agency = userSession.getCurrentAgency();

  return (
    <div className="navbar">
      <img
        src={agency?.logo || noLogo}
        className="agency-logo margin-top-large margin-bottom-small"
      />
      <h1 className="agency-name margin-bottom-large">{agency?.name}</h1>

      <nav>
        <ul>
          <li>
            <div className="nav-option">
              <HomeIcon className="nav-option-icon" />
              <Link to="/">Clientes</Link>
            </div>
          </li>
          <UserRestrictedArea>
            <li>
              <div className="nav-option">
                <UsersIcon className="nav-option-icon" />
                <Link to="/funcionarios">Colaboradores</Link>
              </div>
            </li>
          </UserRestrictedArea>
        </ul>
      </nav>
    </div>
  );
}

export default AgencyNavbar;

import {
  BASE_URL,
  apiDefaultErrorHandler,
  httpServiceCreate,
  httpServiceDelete,
  httpServiceGet,
  httpServiceUpdate,
} from "../utils/serviceUtils";

const getTasks = async ({ ...props }) => {
  try {
    const url = new URL(`${BASE_URL}/task`);
    const data = await httpServiceGet({ url, ...props });
    return data;
  } catch (error) {
    apiDefaultErrorHandler(error);
    return null;
  }
};

const getTaskById = async (taskId) => {
  try {
    const url = new URL(`${BASE_URL}/task/${taskId}`);
    const data = await httpServiceGet({ url });
    return data;
  } catch (error) {
    apiDefaultErrorHandler(error);
    return null;
  }
};

const createTask = async (payload) => {
  try {
    const url = new URL(`${BASE_URL}/task`);
    const data = await httpServiceCreate(url, payload);
    return data;
  } catch (error) {
    apiDefaultErrorHandler(error);
    return null;
  }
};

const updateTask = async (taskId, payload) => {
  try {
    const url = new URL(`${BASE_URL}/task/${taskId}`);
    const data = await httpServiceUpdate(url, payload);
    return data;
  } catch (error) {
    apiDefaultErrorHandler(error);
    return null;
  }
};

const updateTaskStatus = async (taskId, payload) => {
  try {
    const url = new URL(`${BASE_URL}/task/${taskId}/status`);
    const data = await httpServiceUpdate(url, payload);
    return data;
  } catch (error) {
    apiDefaultErrorHandler(error);
    return null;
  }
};

const deleteTask = async (taskId) => {
  try {
    const url = new URL(`${BASE_URL}/task/${taskId}`);
    await httpServiceDelete(url);
    return true;
  } catch (error) {
    apiDefaultErrorHandler(error);
    return false;
  }
};

const taskService = {
  getTasks,
  getTaskById,
  createTask,
  updateTask,
  updateTaskStatus,
  deleteTask,
};

export default taskService;

import React from "react";
import "./EmployeesPage.scss";
import PageTitle from "../../components/PageTitle/PageTitle";
import { CrudArea, crudModels } from "../../components/CrudArea/CrudArea";

function EmployeesPage() {
  return (
    <div className="page">
      <PageTitle
        title="Colaboradores"
        description="Gerencie os usuários da sua conta"
      />

      <CrudArea model={crudModels.user} />
    </div>
  );
}

export default EmployeesPage;

import React from "react";
import "./Card.scss";

function Card({ children, title = "" }) {
  return (
    <div className="card">
      {title && (
        <div className="card-header margin-bottom-medium">
          <h1>{title}</h1>
        </div>
      )}
      <div className="card-body">{children}</div>
    </div>
  );
}

export default Card;

import React from "react";
import "./ClientComunicationPlanPage.scss";
import PageTitle from "../../components/PageTitle/PageTitle";

function ClientComunicationPlanPage() {
  return (
    <div className="page">
      <PageTitle
        title="Plano de Comunicação"
        description="Faça um plano com tudo que precisa ser produzido"
        className={"margin-bottom-extra-large"}
      />
      <div className="em-breve">
        <h2>EM BREVE</h2>
        <p>
          Cadastre o plano de comunicação do cliente e gere todas as tarefas que
          precisam ser executadas
        </p>
      </div>
    </div>
  );
}

export default ClientComunicationPlanPage;
